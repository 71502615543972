import { profileBGColor, UserProfileImage } from "@/assets/images";
import { Box } from "@mui/material";
import { useRef, useState } from "react";

const UserImage = (props) => {
  const { formik } = props;
  const [selectedImage, setSelectedImage] = useState(UserProfileImage); // State to store selected image
  const inputRef = useRef<HTMLInputElement>(null);
  const handleImageClick = () => {
    inputRef.current?.click(); // Trigger click on hidden input
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const imageURL = URL.createObjectURL(file);
      formik.setFieldValue("profileImage", file);
      setSelectedImage(imageURL); // Update state with the new image URL
    }
  };
  function isValidURL(input) {
    try {
      // If the URL constructor doesn't throw an error, the input is a valid URL
      new URL(input);
      return true;
    } catch (err) {
      // If an error is thrown, it's not a valid URL
      return false;
    }
  }

  return (
    <Box sx={MainBoxStyle}>
      <img
        src={profileBGColor}
        alt="profile bg"
        style={{ height: "100%", width: "100%", borderRadius: "16px" }}
      />
      <Box sx={ImageBoxStyle}>
        <img
          src={
            isValidURL(formik.values.profileImage)
              ? formik.values.profileImage
              : selectedImage
          }
          alt="user image"
          style={{ height: "100%", width: "100%", borderRadius: "50%" }}
        />
        <Box sx={SvgBoxStyle} onClick={handleImageClick}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_531_45039)">
              <path
                d="M5 7H6C6.53043 7 7.03914 6.78929 7.41421 6.41421C7.78929 6.03914 8 5.53043 8 5C8 4.73478 8.10536 4.48043 8.29289 4.29289C8.48043 4.10536 8.73478 4 9 4H15C15.2652 4 15.5196 4.10536 15.7071 4.29289C15.8946 4.48043 16 4.73478 16 5C16 5.53043 16.2107 6.03914 16.5858 6.41421C16.9609 6.78929 17.4696 7 18 7H19C19.5304 7 20.0391 7.21071 20.4142 7.58579C20.7893 7.96086 21 8.46957 21 9V18C21 18.5304 20.7893 19.0391 20.4142 19.4142C20.0391 19.7893 19.5304 20 19 20H5C4.46957 20 3.96086 19.7893 3.58579 19.4142C3.21071 19.0391 3 18.5304 3 18V9C3 8.46957 3.21071 7.96086 3.58579 7.58579C3.96086 7.21071 4.46957 7 5 7Z"
                stroke="#374151"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 13C9 13.7956 9.31607 14.5587 9.87868 15.1213C10.4413 15.6839 11.2044 16 12 16C12.7956 16 13.5587 15.6839 14.1213 15.1213C14.6839 14.5587 15 13.7956 15 13C15 12.2044 14.6839 11.4413 14.1213 10.8787C13.5587 10.3161 12.7956 10 12 10C11.2044 10 10.4413 10.3161 9.87868 10.8787C9.31607 11.4413 9 12.2044 9 13Z"
                stroke="#374151"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_531_45039">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Box>
      </Box>
      <Box sx={ProfileBoxStyle}>
        {/* Profile line */}

        <Box sx={ProfileBoxInnerStyle} />
      </Box>
      {/* Hidden file input */}
      <input
        type="file"
        accept="image/*"
        ref={inputRef}
        style={{ display: "none" }}
        onChange={handleImageChange} // Handle file selection
      />
    </Box>
  );
};
export default UserImage;
const MainBoxStyle = {
  width: { xs: "100%", md: "760px", lg: "100%" },
  height: { xs: "120px", sm: "160px", md: "200px" },
  borderRadius: "16px",
  position: "relative",
};
const ImageBoxStyle = {
  position: "absolute",
  bottom: "-32%",
  left: { xs: "16px", sm: "20px", md: "24px" },
  height: { xs: "80px", sm: "100px", md: "120px" },
  width: { xs: "80px", sm: "100px", md: "120px" },
  borderRadius: "50%",
  zIndex: "100",
};
const SvgBoxStyle = {
  position: "absolute",
  bottom: "0%",
  left: "70%",
  height: "40px",
  width: "40px",
  borderRadius: "50%",
  bgcolor: " #FFFFFFCC",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
};
const ProfileBoxStyle = {
  position: "absolute",
  bottom: "-32%",
  left: { xs: "16px", sm: "20px", md: "24px" },
  height: { xs: "80px", sm: "100px", md: "120px" },
  width: { xs: "80px", sm: "100px", md: "120px" },
  borderRadius: "50%",
};
const ProfileBoxInnerStyle = {
  position: "absolute",
  top: { xs: "-10%", sm: "-9.5%", md: "-7.5%" },
  right: { xs: "-10%", sm: "-9.5%", md: "-7.5%" },
  height: { xs: "96px", sm: "120px", md: "138px" },
  width: { xs: "96px", sm: "120px", md: "138px" },
  borderRadius: "50%",
  bgcolor: "transparent",
  border: { xs: "4px solid #7C4CE1", sm: "6px solid #7C4CE1" },
};
