import http from "../../services/http.service";
import { createAsyncThunk } from "@reduxjs/toolkit";

interface resetPasswordOtpVerificationResponse {
  message: string;
}
interface resendPasswordResetOtpResponse {
  message: string;
}
interface verifyresendOtpResponse {
  message: string;
}
interface emialOtpVerificationResponse {
  message: string;
  user: any;
}

export const resetPasswordOtpVerification = createAsyncThunk<
  resetPasswordOtpVerificationResponse,
  { otp: string; email: string },
  { rejectValue: any }
>(
  "auth/reset-password-otp-verification",
  async (data: { otp: string; email: string }, thunkAPI) => {
    const [response, error] = await http.post(
      "/auth/reset-password-otp-verification",
      data
    );
    if (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
    return response.data;
  }
);

export const resendPasswordResetOtp = createAsyncThunk<
  resendPasswordResetOtpResponse,
  { email: string },
  { rejectValue: any }
>(
  "auth/resend-password-reset-otp",
  async (data: { email: string }, thunkAPI) => {
    const [response, error] = await http.post(
      "/auth/resend-password-reset-otp",
      data
    );
    if (error) {
      return thunkAPI.rejectWithValue(error.resposne.data);
    }
    return response.data;
  }
);

// email verification

export const emialOtpVerification = createAsyncThunk<
  emialOtpVerificationResponse,
  { otp: string },
  { rejectValue: any }
>("/auth/verify-email", async (data: { otp: string }, thunkAPI) => {
  const [response, error] = await http.post("/auth/verify-email", data);
  if (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
  return response.data;
});

export const verifyresendOtp = createAsyncThunk<
  verifyresendOtpResponse,
  null,
  { rejectValue: any }
>("/auth/resend-email-verification-otp", async (_, thunkAPI) => {
  const [response, error] = await http.get(
    "/auth/resend-email-verification-otp"
  );
  if (error) {
    return thunkAPI.rejectWithValue(error.resposne.data);
  }
  return response.data;
});
