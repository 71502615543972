import React from 'react';
import { Box, Typography } from "@mui/material";
import PlanSummaryCard from './PlanSummaryCard';
import PaymentMethodCard from './PaymentMethodCard';
import BillingHistory from './BillingHistory';
import { useTranslation } from 'react-i18next';


const SubscriptionComponent = () => {
    const {t}= useTranslation()
   

    return (
        <Box sx={BoxStyle}>
            <Typography variant="body2">
                {t("Dashboard.Subscription_and_Billing")}
            </Typography>
            <Typography variant='body1'>
            {t("Dashboard.Subscription_and_Billing_desc")}
            </Typography>
            <Box sx={InnerBoxStyle}>
                <Box sx={CardBoxStyle}>
                    <PlanSummaryCard t={t} />
                    <PaymentMethodCard t={t}/>
                </Box>
                <BillingHistory t={t} />
            </Box>
        </Box>
    )
}

export default SubscriptionComponent

const BoxStyle = {
    mt: "24px",
    width: { xs: "100%", md: "760px", lg: "100%" },
    px: { xs: "12px", sm: "24px", md: "0" },
    borderRadius: "8px",
    border: "1px solid #DFE4EA",
    boxShadow: " 0px 0px 4px 0px #E9E9E924",
    padding: { xs: "16px", sm: "20px", md: "24px" },
    display: "flex",
    flexDirection: "column",
    gap: "32px"
};

const InnerBoxStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "38px",
};
const CardBoxStyle = {
    display: "flex",
    gap: "22px",
}