import { Box, Button, Dialog, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const DeleteModel = (props) => {
  const { handleClose, open, handleSubmit, title, subTitle } = props;
  const [deleteValue, setDeleteValue] = React.useState("");
  const handleChange = (e) => {
    setDeleteValue(e.target.value);
  };
  const { t } = useTranslation();
  useEffect(() => {
    if (open === true) {
      setDeleteValue("");
    }
  }, [open]);

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "460px", md: "600px" },
            padding: "24px",
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: "600" }}>
            {title}
          </Typography>

          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "400", py: "1rem" }}
          >
            {subTitle}
          </Typography>
          <TextField
            placeholder={t("Personal_Information.Write_to_Proceed")}
            onChange={handleChange}
            fullWidth
            value={deleteValue}
            onKeyDown={(event) => {
              if (event.key === "Enter" && deleteValue === "delete") {
                handleSubmit();
                handleClose();
              }
            }}
          />
          <Button
            onClick={handleSubmit}
            disabled={deleteValue !== "delete"}
            sx={{
              bgcolor: "#FF3B30",
              mt: "12px",
              width: "100%",
              color: "white",
              height: "38px",
              ":hover": { bgcolor: "#FF3B30" },
              "&:disabled": {
                backgroundColor: "#f7f6f3",
              },
            }}
          >
            {t("Personal_Information.Delete")}
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export default DeleteModel;
