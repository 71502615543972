import { Box, Button, Typography } from "@mui/material";
import { PrimaryDoubleTickIcon, WhiteDoubleTickIcon } from "@/assets/images";
import { motion } from "framer-motion";
interface PlanCardProps {
  plan: Plan;
  type: number;
  buttonClick: () => void;
}

interface Feature {
  name: string;
  duration: number;
}

interface Plan {
  name: string;
  description: string;
  monthlyPrice: number;
  yearlyPrice: number;
  features: Feature[];
}
const PlanCard: React.FC<PlanCardProps> = ({ plan, type, buttonClick }) => {

  const isDarkTheme = plan && plan.name === "Pro";

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 200 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 2, type: "spring" }}
      sx={{
        minWidth: isDarkTheme ? "360px" : "290px",
        padding: "24px",
        borderRadius: "24px",
        display: "flex",
        flexDirection: "column",
        gap: "40px",
        border: isDarkTheme ? "1px solid #7C4CE1" : "none",
        backgroundColor: isDarkTheme ? "#7C4CE1" : "#FFFFFF",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "32px" }}>
          <Typography
            variant="h2"
            sx={{ fontSize: "48px", color: isDarkTheme ? "#fff" : "#1E293B" }}
          >$
            {type == 0 ? plan.monthlyPrice : plan.yearlyPrice}
            <span style={{ fontSize: "16px", fontWeight: "500", color: isDarkTheme ? "#fff" : "#667085" }}>
              {type == 0 ? "/mo" : "/year"}
            </span>
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <Typography variant="h3" sx={{ fontSize: "30px", color: isDarkTheme ? "#fff" : "#1E293B" }}>
              {plan.name}
            </Typography>
            <Typography variant="subtitle1" sx={{ color: isDarkTheme ? "#FFFFFF" : "#667085" }}>
              {plan.description}
            </Typography>
          </Box>
        </Box>

        {plan.name === "Pro" && (
          <Box
            sx={{
              height: "40px",
              backgroundColor: "#fff",
              padding: "12px 24px",
              borderRadius: "88px",
              width: "fit-content",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="caption" sx={{ color: "#1E293B", fontWeight: "500" }}>
              MOST POPULAR
            </Typography>
          </Box>
        )}
      </Box>

      <Button
        sx={{
          width: "100%",
          padding: "12px 20px",
          backgroundColor: "#FFFFFF",
          borderRadius: "6px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: isDarkTheme ? "none" : "1px solid #7C4CE1",
          ":hover": { backgroundColor: "#E0DAF9" },
        }}
        onClick={buttonClick}
      >
        <Typography variant="caption" sx={{ color: isDarkTheme ? "#1E293B" : "#7C4CE1" }}>
          {plan.name === "Freemium" ? "Continue with free" : plan.name === "Enterprise" ? "Contact Slaes" : "Choose Plan"}
        </Typography>
      </Button>

      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        {plan.features.map((item, index) => (
          <Box
            component={motion.div}
            initial={{ opacity: 0, x: isDarkTheme ? 300 : -300 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: item.duration, type: "spring" }}
            key={index}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <img
              src={isDarkTheme ? WhiteDoubleTickIcon : PrimaryDoubleTickIcon}
              alt="tick"
              style={{ height: "24px", width: "24px" }}
            />
            <Typography variant="body1" sx={{ fontWeight: "400", ml: "12px", color: isDarkTheme ? "#fff" : "#1E293B" }}>
              {item.name}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PlanCard;
