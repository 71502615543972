export const hoverRegex = /[^{}]*\s*:[\s]*hover\s*{[^}]*}/g;
export const rootConstant = /(:root\s*\{[^}]+\})/g;
export const fontBodyRegix = /--font-body:\s*[^;]+;/g;
export const FontFamilyGoogle =
  /<link[^>]*href=["']https:\/\/fonts\.googleapis\.com[^"']*["'][^>]*>/;
export const ROOT_REGIX = /:root\s*{([^}]*)}/;

export const disposableEmailDomains = [
  "mailinator.com",
  "10minutemail.com",
  "yopmail.com",
  "tempmail.com",
  "trashmail.com",
  "guerrillamail.com",
];