import { PrimaryTickIcon } from "@/assets/images";
import { mapLanguageData } from "@/components/LanguageData";
import { setLanguage } from "@/redux/slices/authSlice";
import { setDirection } from "@/redux/slices/themeSlice";
import { useAppDispatch } from "@/redux/store";
import { Box, Typography } from "@mui/material";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const PreferencesComp = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const i18nextLng = localStorage.getItem("i18nextLng") || "en";
  return (
    <Box sx={BoxStyle}>
      <Typography variant="body2">
        {t("Dashboard.Choose_Your_Language")}
      </Typography>
      <Box sx={InnerBoxStyle}>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "12px" }}>
          {" "}
          {mapLanguageData?.map((item, index) => (
            <Box
              onClick={() => {
                i18next.changeLanguage(item?.value as string);
                dispatch(setLanguage(item?.value as string));
                dispatch(setDirection(item?.value as string));
              }}
              key={index}
              sx={{
                border:
                  i18nextLng === item?.value
                    ? "1px solid transparent"
                    : "1px solid #DFE4EA",
                backgroundColor:
                  i18nextLng === item?.value ? "#F9FAFB" : "transparent",
                borderRadius: "8px",
                width: "236px",
                padding: "12px",
                mt: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ mr: "16px", height: "24px", width: "24px" }}>
                  {item?.svgSrc}
                </Box>
                <Typography variant="subtitle2"> {item?.name}</Typography>
              </Box>
              {i18nextLng === item?.value && (
                <Box>
                  <img src={PrimaryTickIcon} alt="tick" />
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default PreferencesComp;

const BoxStyle = {
  mt: "24px",
  width: { xs: "100%", md: "760px", lg: "100%" },
  px: { xs: "12px", sm: "24px", md: "0" },
  borderRadius: "8px",
  border: "1px solid #DFE4EA",
  boxShadow: " 0px 0px 4px 0px #E9E9E924",
  padding: { xs: "16px", sm: "20px", md: "24px" },
};
const InnerBoxStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: { xs: "column", sm: "row" },
};
