import { AuthBgVideo } from "@/assets/images";
import { Box } from "@mui/material";
import React from "react";


interface PublicLayoutProps {
  children: React.ReactNode;
  AuthPage?: boolean;
}

const Index: React.FC<PublicLayoutProps> = (props) => {
  const { children, AuthPage } = props;

  return (
    <Box sx={MainBoxStyle(AuthPage)}>
      {AuthPage && (
        <Box
          component="video"
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: 0,
            opacity: 0.5, // 50% opacity
            transform: "rotate(-180deg)", // -180° rotation
            mixBlendMode: "lighten", // Lighten blend mode
          }}
          autoPlay
          muted
          loop
          src={AuthBgVideo}
        />
      )}
      <Box sx={BoxStyle(AuthPage)}>
        <Box 
          sx={{ 
            maxWidth: "1920px", 
            width: "100%",
            position: "relative",
            zIndex: 1
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Index;

// styling

const BoxStyle = (AuthPage) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  height: AuthPage ? "100%" : { xs: "100%", sm: "82%" },
  p: "1.2rem .6rem",
  overflow: "auto",
  zIndex: 1,
});

const MainBoxStyle = (AuthPage) => ({
  bgcolor: !AuthPage ? "#fff" : "#33166a",
  height: AuthPage ? { xs: "100%", sm: "100vh" } : "100%",
  overflow: "auto",
  mx: "auto",
  position: "relative",
});