import { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Alert,
  Collapse,
  IconButton,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

// Styled components
const StyledCard = styled(Card)(() => ({
  maxWidth: 500,
  backgroundColor: "transparent",
  boxShadow: "none",
  border: "none",
}));

const LockIconContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const AnimatedLockIcon = styled(LockIcon)(({ theme }) => ({
  fontSize: 80,
  color: theme.palette.error.main,
  animation: "pulse 2s infinite",
  "@keyframes pulse": {
    "0%": {
      transform: "scale(1)",
      opacity: 1,
    },
    "50%": {
      transform: "scale(1.1)",
      opacity: 0.8,
    },
    "100%": {
      transform: "scale(1)",
      opacity: 1,
    },
  },
}));

const NoPermissionCard = () => {
  const [expanded, setExpanded] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleRequestAccess = () => {
    setShowContactForm(!showContactForm);
  };

  return (
    <StyledCard>
      <CardContent
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
          border: "none",
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          color="error"
          fontWeight="bold"
          textAlign="center"
        >
          Access Denied
        </Typography>

        <LockIconContainer>
          <AnimatedLockIcon />
        </LockIconContainer>

        <Typography variant="body1" textAlign="center" gutterBottom>
          You don't have permission to access this resource.
        </Typography>

        <Typography variant="body2" color="text.secondary" textAlign="center">
          Please contact your administrator if you believe this is a mistake.
        </Typography>

        <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
          <IconButton
            size="small"
            color="primary"
            onClick={handleExpandClick}
            aria-expanded={expanded}
          >
            <InfoIcon />
          </IconButton>
          <Typography variant="body2" color="primary">
            Why am I seeing this?
          </Typography>
        </Box>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box mt={2}>
            <Alert
              severity="info"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setExpanded(false)}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <Typography variant="body2">
                This resource requires special permissions that haven't been
                granted to your account. This could be due to your user role,
                subscription level, or specific access restrictions.
              </Typography>
            </Alert>
          </Box>
        </Collapse>

        <Collapse in={showContactForm} timeout="auto" unmountOnExit>
          <Box mt={2} p={2} bgcolor="background.paper" borderRadius={1}>
            <Typography variant="subtitle2" gutterBottom>
              Request Access Form
            </Typography>
            <Typography variant="body2" color="text.secondary" mb={1}>
              Your request will be sent to the system administrator.
            </Typography>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={handleRequestAccess}
              fullWidth
            >
              Cancel Request
            </Button>
          </Box>
        </Collapse>
      </CardContent>
    </StyledCard>
  );
};

export default NoPermissionCard;
