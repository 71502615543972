import PrimaryButton from "@/components/buttons/PrimaryButton";
import PasswordTextFiled from "@/components/textField/PasswordTextFiled";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { ChangePassword } from "@/redux/thunk/authThunk";
import { ChangePasswordSchema } from "@/utils/schema";
import { Box, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import StatsPasswordUpdate from "./StatsPasswordUpdate";

const SecurityComp = () => {
  const { t } = useTranslation();
  const { loading } = useAppSelector((state) => state.auth.auth);
  // const [open, setOpen] = React.useState(false);
  const [passwordStrength, setPasswordStrength] = useState({
    hasEightChars: false,
    hasUpperCase: false,
    hasNumber: false,
    hasSpecial: false,
    strengthLevel: 0, // 0-4: Very Weak, Weak, Medium, Strong
  });

  // Animation states for each validation item
  const [animations, setAnimations] = useState({
    hasEightChars: false,
    hasUpperCase: false,
    hasNumber: false,
    hasSpecial: false,
  });

  // Calculate password strength whenever the password changes
  const checkPasswordStrength = (password) => {
    const newState = {
      hasEightChars: password.length >= 8,
      hasUpperCase: /[A-Z]/.test(password),
      hasNumber: /[0-9]/.test(password),
      hasSpecial: /[^A-Za-z0-9]/.test(password),
    };

    // Calculate strength level (0-4)
    let strengthLevel = 0;
    if (password.length > 0) strengthLevel = 1; // Very Weak
    if (newState.hasEightChars) strengthLevel = 2; // Weak
    if (
      (newState.hasUpperCase && newState.hasNumber) ||
      (newState.hasUpperCase && newState.hasSpecial) ||
      (newState.hasNumber && newState.hasSpecial)
    )
      strengthLevel = 3; // Medium
    if (
      newState.hasEightChars &&
      newState.hasUpperCase &&
      newState.hasNumber &&
      newState.hasSpecial
    )
      strengthLevel = 4; // Strong

    // Track which validations changed to trigger animations
    const newAnimations = { ...animations };

    // For each validation rule, if it changed from false to true, trigger animation
    Object.keys(newState).forEach((key) => {
      if (!animations[key] && newState[key]) {
        newAnimations[key] = true;
      }
    });

    setAnimations(newAnimations);
    setPasswordStrength({ ...newState, strengthLevel });
  };

  const dispatch = useAppDispatch();
  const formik = useFormik({
    initialValues: {
      confirmPassword: "",
      currentPassword: "",
      newPassword: "",
    },
    validationSchema: () => ChangePasswordSchema(),
    onSubmit: async (values) => {
      const data = {
        password: values.newPassword.trim(),
        currentPassword: values.currentPassword.trim(),
      };
      const resultAction = await dispatch(ChangePassword(data));
      if (ChangePassword.fulfilled.match(resultAction)) {
        toast.success(
          resultAction?.payload?.message || "Password changed Successfully"
        );
        // setOpen(false);
      } else if (ChangePassword.rejected.match(resultAction)) {
        toast.error(resultAction?.payload?.reason || "Something went wrong");
      }
      formik.resetForm();
      // Reset animations and password strength
      setAnimations({
        hasEightChars: false,
        hasUpperCase: false,
        hasNumber: false,
        hasSpecial: false,
      });
      setPasswordStrength({
        hasEightChars: false,
        hasUpperCase: false,
        hasNumber: false,
        hasSpecial: false,
        strengthLevel: 0,
      });
    },
  });

  // Update password strength when newPassword changes
  useEffect(() => {
    checkPasswordStrength(formik.values.newPassword);
  }, [formik.values.newPassword]);

  // const handleClose = () => {
  //   // setOpen(false);
  //   formik.resetForm();
  //   setPasswordStrength({
  //     hasEightChars: false,
  //     hasUpperCase: false,
  //     hasNumber: false,
  //     hasSpecial: false,
  //     strengthLevel: 0,
  //   });

  //   setAnimations({
  //     hasEightChars: false,
  //     hasUpperCase: false,
  //     hasNumber: false,
  //     hasSpecial: false,
  //   });
  // };

  const { values, handleBlur, handleSubmit, handleChange, touched, errors } =
    formik;

  // Get strength data for UI
  const getStrengthData = () => {
    const { strengthLevel } = passwordStrength;
    return (
      mapData[strengthLevel - 1] || { title: "Very Weak", color: "#e40808" }
    );
  };

  const strengthData = getStrengthData();

  return (
    <Box sx={BoxStyle}>
      <Typography variant="body2">
        {t("Personal_Information.Security")}
      </Typography>
      <Box>
        <Typography variant="subtitle1" sx={{ my: { xs: "16px", md: "18px" } }}>
          {t("Personal_Information.Keep_your_account_secure")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <StatsPasswordUpdate
            values={values}
            strengthData={strengthData}
            passwordStrength={passwordStrength}
            mapData={mapData}
            animations={animations}
          />
          <Box
            component={"form"}
            onSubmit={handleSubmit}
            sx={{
              width: { xs: "100%", sm: "460px", md: "600px" },
              padding: { xs: "0px", sm: "20px", md: "24px" },
            }}
          >
            <PasswordTextFiled
              title={t("Personal_Information.Current_Password")}
              placeholder={t("Personal_Information.Enter_current_password")}
              name="currentPassword"
              value={values?.currentPassword}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched?.currentPassword}
              error={errors.currentPassword}
            />
            <PasswordTextFiled
              title={t("Personal_Information.New_Password")}
              placeholder={t("Personal_Information.Enter_New_Password")}
              name="newPassword"
              value={values?.newPassword}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched?.newPassword}
              error={errors.newPassword}
            />
            <PasswordTextFiled
              title={t("Personal_Information.Confirm_Password")}
              placeholder={t("Personal_Information.Enter_Confirm_Password")}
              name="confirmPassword"
              value={values?.confirmPassword}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched?.confirmPassword}
              error={errors.confirmPassword}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              {" "}
              <PrimaryButton
                loading={loading}
                style={{
                  width: { xs: "100%", sm: "auto" },
                  minWidth: "160px",
                  mt: "12px",
                }}
                compLeft={true}
                text={t("Personal_Information.Change_Password")}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SecurityComp;

const BoxStyle = {
  mt: "24px",
  width: { xs: "100%", md: "760px", lg: "100%" },
  px: { xs: "12px", sm: "24px", md: "0" },
  borderRadius: "8px",
  border: "1px solid #DFE4EA",
  boxShadow: " 0px 0px 4px 0px #E9E9E924",
  padding: { xs: "16px", sm: "20px", md: "24px" },
};

const mapData = [
  { title: "Very Weak", color: "#e40808" },
  { title: "Weak", color: "#e40808" },
  { title: "Medium", color: "#ffd800" },
  { title: "Strong", color: "#2cb117" },
];
