import { Box, Button, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import curvedArrow from "../../../assets/images/curved_arrow.svg";
import PaymentModal from "./PaymentModal";
import PlanCard from "./PlanCard";
import { useNavigate } from "react-router-dom";
import { setGuestAuthModel } from "@/redux/slices/userSlice";
import { useAppDispatch, useAppSelector } from "@/redux/store";

const PricingSection = () => {
  const navigate = useNavigate();
  const { accessToken } = useAppSelector((state) => state.auth.auth);
  const dispatch = useAppDispatch();
  const [selectedButton, setSelectedButton] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const handleChoosePlanClick = () => {
    setIsModalOpen(true);
  };
  const handlePlanClicked = (plan) => {
    setSelectedPlan(plan);
    if (plan.name === "Pro" || plan.name === "Team") {
      if (!accessToken) {
        dispatch(setGuestAuthModel(true));
      } else {
        handleChoosePlanClick();
      }
    } else if (plan.name === "Freemium") {
      navigate("/my-projects");
    }
  };

  const handleToggle = (index) => {
    setSelectedButton(index);
  };



  const closeModal = () => {
    setIsModalOpen(false);
  };
  const { t } = useTranslation();

  const motionSettings = {
    initial: { opacity: 0, y: 100 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 2, type: "spring" },
  };

  return (
    <Box sx={{ my: { xs: "2.4rem", sm: "2.8rem", md: "3.4rem", display: "flex", flexDirection: "column", gap: "40px", padding: "40px 0px", alignItems: "center" } }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: "48px"
        }}
        component={motion.div}
        transition={{ ...motionSettings.transition, delay: 1.6 }}
        {...motionSettings}
      >
        <Typography
          variant="h2"
          textAlign={"center"}
          sx={{
            fontSize: { xs: "24px", sm: "30px", md: "36px" },
            // width: { xs: "90%", sm: "60%", md: "50%", lg: "40%" },
          }}
        >
          {t("Pricing.Powerful_Features_for")}
          <br />
          <span style={{ color: "#7C4CE1" }}>
            {" "}
            {t("Pricing.Powerful_Creators")}
          </span>
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontWeight: "400", color: "#667085" }}
        >
          {t("Pricing.Pricing_built")}
        </Typography>
      </Box>
      <Box sx={ButtonContainerStyle}>
        <Button
          sx={{
            backgroundColor: selectedButton === 0 ? "#7C4CE1" : "transparent",
            borderRadius: "33px",
            color: selectedButton === 0 ? "#fff" : "#627065",
            textTransform: "none",
            transition: "0.3s",
            "&:hover": {
              backgroundColor: selectedButton === 0 ? "#9B63E8" : "transparent",
            },
            padding: "4px 16px",
          }}
          onClick={() => handleToggle(0)}
        >
          Monthly
        </Button>

        <Button
          sx={{
            backgroundColor: selectedButton === 1 ? "#7C4CE1" : "transparent",
            borderRadius: "33px",
            color: selectedButton === 1 ? "#fff" : "#627065",
            textTransform: "none",
            transition: "0.3s",
            "&:hover": {
              backgroundColor: selectedButton === 1 ? "#9B63E8" : "transparent",
            },
            padding: "4px 16px",
          }}
          onClick={() => handleToggle(1)}
        >
          Yearly
        </Button>
        <img
          alt="arrow"
          src={curvedArrow}
          style={{
            position: "absolute",
            top: "-20px",
            right: "-50px",
            width: "62px",
            height: "24px"
          }}
        />
        <Typography variant="subtitle2" sx={{ color: "#7C4CE1", position: "absolute", right: "-140px" }}>
          SAVE UP TO 30%
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingX: "80px",
          maxWidth: "1440px"
        }}
      >
        {planData.map((plan, index) => (
          <PlanCard key={index} plan={plan} type={selectedButton} buttonClick={() => handlePlanClicked(plan)} />
        ))}

      </Box>
      <PaymentModal open={isModalOpen} onClose={closeModal} plan={selectedPlan} type={selectedButton} />
    </Box>
  );
};

export default PricingSection;

const planData = [
  {
    name: "Freemium",
    description: "Try it as long as you like",
    monthlyPrice: 0,
    yearlyPrice: 0,
    features: [
      { name: "1 user", duration: 2 },
      { name: "3 websites", duration: 2.2 },
      { name: "30 free edits", duration: 2.4 },
      { name: "Subdomain on icōd", duration: 2.6 },
      { name: "50 MB free space", duration: 2.8 },
      { name: "Pre defined templates", duration: 3 },
      { name: "Ads or branding on the website", duration: 3 },
      { name: "Site optimized for mobile", duration: 3 },
      { name: "Free SSL/TLS certificates for 1st month", duration: 3 },
    ]
  },
  {
    name: "Pro",
    description: "Limitless possibilities",
    monthlyPrice: 15,
    yearlyPrice: 165,
    features: [
      { name: "1 user", duration: 2 },
      { name: "Unlimited websites", duration: 2.2 },
      { name: "Unlimited edits", duration: 2.4 },
      { name: "Predefined Templates", duration: 2.6 },
      { name: "AI Template Feature", duration: 2.8 },
      { name: "Free Email", duration: 3 },
      { name: "$15 of monthly credits", duration: 3.2 },
      { name: "Pay as you go for additional usage", duration: 3.2 },
      { name: "Shared instance for compute and hosting", duration: 3.2 },
      { name: "Site optimized for mobile", duration: 3.2 },
      { name: "Custom subdomain on icod", duration: 3.2 },
      { name: "Code download/export option", duration: 3.2 },
      { name: "Free SSL/TLS certificates", duration: 3.2 },
    ]
  },
  {
    name: "Team",
    description: "Best for small teams",
    monthlyPrice: 30,
    yearlyPrice: 330,
    features: [
      { name: "Multi users", duration: 2 },
      { name: "Unlimited websites", duration: 2.2 },
      { name: "Unlimited edits", duration: 2.4 },
      { name: "Predefined Templates", duration: 2.6 },
      { name: "AI Template Feature", duration: 2.8 },
      { name: "Unlimited emails", duration: 3 },
      { name: "$30 monthly credits", duration: 3.2 },
      { name: "Pay as you go for additional usage", duration: 3.2 },
      { name: "Option to add LLM key of your own choice", duration: 3.2 },
      { name: "Custom hosting", duration: 3.2 },
      { name: "Site optimized for mobile", duration: 3.2 },
      { name: "Custom subdomain on icod", duration: 3.2 },
      { name: "Code download/export option", duration: 3.2 },
      { name: "Free SSL/TLS certificates", duration: 3.2 },
    ]
  },
  {
    name: "Enterprise",
    description: "Best for larger teams",
    monthlyPrice: 50,
    yearlyPrice: 550,
    features: [
      { name: "Team Plan", duration: 2 },
      { name: "Dedicated servers for compute", duration: 2.2 },
    ]
  }
]
// const PreviewButtonStyle = {
//   borderColor: "#fff",
//   mx: "8px",
//   border: "2px solid #7C4CE1",
//   color: "#7C4CE1",
//   width: "100%",
//   mt: "40px",
// };

const ButtonContainerStyle = {
  display: "flex",
  width: "fit-content",
  height: "48px",
  gap: "8px",
  padding: "8px",
  borderRadius: "27px",
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 10px 15px 0px rgba(24, 59, 86, 0.3)",
  position: "relative"
}