import React, { useEffect } from "react";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { acceptcolleboratoeRequest } from "@/redux/thunk/collaboratorThunk.js";
import PricingPage from "@/pages/pricing/index.js";
import VerifiedRoutes from "./VerifiedRoutes.js";

const Login = React.lazy(() => import("../pages/auth/login/LoginPage.js"));
const Home = React.lazy(() => import("../pages/home/LandingPage.js"));
const Generate = React.lazy(
  () => import("../pages/createWebsite/GenerateApp.js")
);
const NotFound = React.lazy(() => import("../pages/notFound"));
const SiteBuilder = React.lazy(() => import("../pages/siteBuilder"));
const FullStackBuilder = React.lazy(
  () => import("../pages/sitePreview/WebApp.js")
);
const SitePreview = React.lazy(() => import("../pages/sitePreview"));
const WebAppPreview = React.lazy(
  () => import("../pages/sitePreview/WebApp.js")
);
const CreateWebsite = React.lazy(
  () => import("../pages/createWebsite/CreateWebiste.js")
);
const ForgetPassword = React.lazy(() => import("../pages/auth/forgetPassword"));
const SignUp = React.lazy(() => import("../pages/auth/signup/SignUpPage.js"));
const Prototype = React.lazy(() => import("../pages/Prototype"));
const Concept = React.lazy(() => import("../pages/Concept"));
const VerifyEmail = React.lazy(() => import("../pages/auth/verifyEmail"));
const GoogleCallback = React.lazy(
  () => import("../pages/auth/google/callback")
);
// const TestComponent = React.lazy(() => import("../pages/TestComponent.jsx"));
const Projects = React.lazy(
  () => import("@/pages/dashboard/components/Projects.js")
);
const SharedProjects = React.lazy(
  () => import("@/pages/dashboard/components/SharedProjects.js")
);
const UserProfile = React.lazy(() => import("@/pages/profile/index.js"));

// Define your routes
const publicRoutes = [
  { path: "/generate", element: <Generate /> },
  { path: "/concept/:projectId", element: <Concept /> },
  { path: "/prototype/:projectId", element: <Prototype /> },
  { path: "/login", element: <Login /> },
  { path: "/signup", element: <SignUp /> },
  { path: "/forget-password", element: <ForgetPassword /> },
  { path: "/google/callback", element: <GoogleCallback /> },
  // { path: "/test", element: <TestComponent /> },
];
// Routes that require authentication but not verification
const unverifiedRoutes = [{ path: "/verify-email", element: <VerifyEmail /> }];
const verifiedRoutes = [
  { path: "/profile", element: <UserProfile /> },
  { path: "/create-website", element: <CreateWebsite /> },
  { path: "/icod-preview/:id", element: <SitePreview /> },
  { path: "/preview-webapp/:id", element: <WebAppPreview /> },
  { path: "/icod-builder/:id", element: <SiteBuilder /> },
  { path: "/builder-webApp/:id", element: <FullStackBuilder /> },
  { path: "/my-projects", element: <Projects /> },
  { path: "/shared-projects", element: <SharedProjects /> },
  { path: "/verify-email", element: <VerifyEmail /> },
  { path: "/google/callback", element: <GoogleCallback /> },
];

const AppRoutes = () => {
  // const { userVerified } = useAppSelector((state) => state.auth.auth);
  const ProfileData = useAppSelector((state) => state.user.user);
  const { accessToken } = useAppSelector((state) => state.auth.auth);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const showLogin = searchParams.get("showLogin");
    const collaborationId = searchParams.get("collaborationId");

    if (showLogin === "true" && accessToken) {
      if (ProfileData && !ProfileData?.isEmailVerified) {
        // If authenticated but not verified, redirect to verify email
        navigate("/verify-email");
      } else {
        // If authenticated and verified, proceed to projects and handle collaboration
        navigate(`/my-projects`);
        if (collaborationId) {
          dispatch(acceptcolleboratoeRequest({ collaborationId }));
        }
      }
    } else if (showLogin === "false") {
      navigate(`/signup?collaborationId=${collaborationId}`);
    } else if (showLogin === "true") {
      navigate(`/login?collaborationId=${collaborationId}`);
    }
  }, [searchParams, accessToken, navigate, dispatch, ProfileData]);

  // Helper function to map routes to <Route> components
  const renderRoutes = (routes) => {
    return routes.map((route, index) => (
      <Route key={index} path={route.path} element={route.element} />
    ));
  };

  return (
    <Routes>
      {/* Make Google Callback Route Always Accessible */}
      <Route path="/google/callback" element={<GoogleCallback />} />

      {/* Public Routes */}
      <Route element={<PublicRoutes />}>{renderRoutes(publicRoutes)}</Route>

      {/* Routes that require authentication but not verification */}
      <Route element={<PrivateRoutes />}>
        {renderRoutes(unverifiedRoutes)}
      </Route>

      {/* Routes that require both authentication and verification */}
      <Route element={<VerifiedRoutes />}>{renderRoutes(verifiedRoutes)}</Route>

      <Route path="/" element={<Home />} />
      <Route path="/pricing" element={<PricingPage />} />

      {/* Always accessible */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
export default AppRoutes;
