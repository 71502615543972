import { mapProfileData } from "@/pages/profile";
import { setProfileTabs } from "@/redux/slices/toolsSlice";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ProfileMobileView = (props) => {
  const { toggleDrawer } = props;
  const dispatch = useAppDispatch();
  const { profileTabs } = useAppSelector((state) => state.tools);
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        height: "calc(100% - 56px)",
      }}
    >
      {mapProfileData?.map((item, index) => (
        <Box
          key={index}
          onClick={() => {
            dispatch(setProfileTabs(index));
            toggleDrawer();
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "40px",
            cursor: "pointer",
            minWidth: "100%",
            backgroundColor: profileTabs === index ? "#FAFDFF" : "transparent",
            transition: "background-color 0.3s ease, transform 0.2s ease",
            ":hover": {
              backgroundColor: "#FAFDFF",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              transition: "transform 0.3s ease",
            }}
          >
            {/* <img
              src={item?.imgSrc}
              style={{
                height: "24px",
                width: "24px",
                marginRight: "12px",
                transition: "transform 0.3s ease",
                transform: profileTabs === index ? "scale(1.1)" : "scale(1)",
              }}
              alt="profile"
            /> */}
            {typeof item?.imgSrc === 'string' ? (
              <img
                src={item.imgSrc}
                alt={item.title}
                style={{
                  height: "24px",
                  width: "24px",
                  transition: "transform 0.3s ease",
                  transform: profileTabs === index ? "scale(1.1)" : "scale(1)",
                }}
              />
            ) : (
              item.imgSrc
            )}
            <Typography
              variant="subtitle1"
              sx={{
                color: profileTabs === index ? "#7C4CE1" : "#000",
                transition: "color 0.3s ease",
                fontWeight: profileTabs === index ? "500" : "normal",
              }}
            >
              {t(item?.title)}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default ProfileMobileView;
