import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { Chatbot_API_Fun, getChatbotHistory } from "../thunk/chatbotThunk";

function handlePending(state: ChatState) {
  state.loading = true;
  state.error = null;
}

function handleRejected(state: ChatState, action: any) {
  state.loading = false;
  state.error = action.payload;
}

export interface ChatState {
  messages: any;
  loading: boolean;
  error: string;
  chatbot_data: any;
}

const initialState: ChatState = {
  messages: [],
  loading: false,
  error: null,
  chatbot_data: null,
};

const chatSlice = createSlice({
  name: "chatbot",
  initialState,
  reducers: {
    addMessage: (state, action) => {
      const isUserPrompt = action.payload.role === "user_prompt";
      if (Array.isArray(state?.messages)) {
        state.messages = {
          messages: [{ message: action.payload.message, role: "user", timestamp: action.payload.timestamp }],
        };
      } else {
        if (isUserPrompt && Array.isArray(state.messages?.messages)) {
          // Add user_prompt message at the beginning of the array
          state.messages.messages = [
            { message: action.payload.message, role: "user",timestamp: action.payload.timestamp  },
            ...state.messages.messages,
          ];
        } else {
          // Add normal messages at the end as before
          state.messages?.messages?.push(action.payload);
        }
      }
    },
    loadHistory: (state, action) => {
      state.messages = action.payload;
    },
    clearChat: (state) => {
      state.messages = [];
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<ChatState>) => {
    builder.addCase(getChatbotHistory.pending, handlePending);
    builder.addCase(getChatbotHistory.fulfilled, (state, payload) => {
      state.messages = payload?.payload || [];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getChatbotHistory.rejected, handleRejected);
    // Chatbot
    builder.addCase(Chatbot_API_Fun.pending, handlePending);
    builder.addCase(Chatbot_API_Fun.fulfilled, (state, payload) => {
      state.chatbot_data = payload?.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(Chatbot_API_Fun.rejected, handleRejected);
  },
});

export const { addMessage, loadHistory, clearChat } = chatSlice.actions;
export default chatSlice.reducer;
