import {
  Box,
  FormHelperText,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const wrapper = {
  height: "20px",
  width: "100%",
  mt: "2px",
};

const errorStyle = { paddingLeft: "5px", mt: 0 };

interface TextFieldSelect {
  title?: string;
  placeholder?: string;
  value: string;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  touched?: boolean;
  errors?: string | undefined;
  name: string;
  style?: any;
  mapData: any[];
}

const TextFieldSelect: React.FC<TextFieldSelect> = (props) => {
  const {
    title,
    placeholder,
    value,
    handleChange,
    handleBlur,
    touched,
    errors,
    name,
    style,
    mapData,
  } = props;
  return (
    <Box
      sx={{
        height: { xs: "82px", sm: "92px" },
        mb: { xs: "16px", sm: "0" },
        width: "100%",
      }}
    >
      {title && (
        <Typography variant="h6" sx={{ pb: "6px", fontWeight: "500" }}>
          {title || "Gender"}
        </Typography>
      )}
      <TextField
        name={name}
        placeholder={placeholder || "Enter here"}
        onChange={handleChange}
        // sx={textField}
        onBlur={handleBlur}
        fullWidth
        value={value}
        SelectProps={{
          IconComponent: KeyboardArrowDownIcon,
          sx: {
            ".MuiSelect-icon": {
              color: "#667085",
              fontWeight: "400",
            },
          },
        }}
        select
        sx={{
          ...style,
          "& .MuiOutlinedInput-root": {
            color: "#667085",
            fontWeight: "400",
            paddingRight: "32px",
          },
        }}
        inputProps={{
          "data-cy": "linked_in-personaldetail", // Cypress ID assigned to the search input element
        }}
        error={touched && Boolean(errors)}
      >
        {mapData?.map((item, index) => (
          <MenuItem key={index} value={item?.value} sx={{ color: "#667085" }}>
            {item?.label}
          </MenuItem>
        ))}
      </TextField>
      <Box sx={wrapper}>
        {touched && Boolean(errors) && (
          <FormHelperText error sx={errorStyle}>
            {touched && errors}
          </FormHelperText>
        )}
      </Box>
    </Box>
  );
};

export default TextFieldSelect;
