import { createSlice } from "@reduxjs/toolkit";
import { deployProject, updateSubDomain } from "../thunk/deploymentThunk";

export interface DeploymentState {
  isLoading: boolean;
  loading: boolean;
  isDeployedDone: boolean;
  deployedUrl: string;
}

const initialState: DeploymentState = {
  isDeployedDone: false,
  isLoading: false,
  loading: false,
  deployedUrl: null,
};

const deploymentSlice = createSlice({
  name: "deployment",
  initialState,
  reducers: {
    setDeployed: (state, action) => {
      state.isDeployedDone = action.payload;
    },
    setDeployedUrl: (state) => {
      state.deployedUrl = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deployProject.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deployProject.fulfilled, (state, { payload }) => {
      state.isDeployedDone = true;
      state.deployedUrl = payload?.url;
      state.isLoading = false;
    });
    builder.addCase(deployProject.rejected, (state) => {
      state.isLoading = false;
    });
    //  update subdomain
    builder.addCase(updateSubDomain.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateSubDomain.fulfilled, (state, { payload }) => {

      state.isDeployedDone = true;
      state.deployedUrl = payload?.url;
      state.loading = false;
    });
    builder.addCase(updateSubDomain.rejected, (state) => {
      state.loading = false;
    });
  },
});
export const { setDeployed, setDeployedUrl } = deploymentSlice.actions;

export default deploymentSlice.reducer;
