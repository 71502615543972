import { Tooltip, Box, IconButton } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { setAIEditorToggle } from "@/redux/slices/toolsSlice";
import { Bot, PencilRuler } from "lucide-react";

const AIEditorToggle = () => {
  const dispatch = useAppDispatch();
  const { AIEditorToggle } = useAppSelector((state) => state.tools);

  const handleAIMode = () => {
    dispatch(setAIEditorToggle(true));
  };

  const handleEditorMode = () => {
    dispatch(setAIEditorToggle(false));
  };

  return (
    <Box 
      className="icod--AI-Editor-controls"
      sx={{
        bgcolor: "#F3F4F6",
        height: "32px",
        padding: "4px 6px",
        display: "flex",
        borderRadius: "6px",
        alignItems: "center",
        gap: "4px"
      }}
    >
      <Tooltip title="Make changes via AI assistant" arrow placement="top">
        <IconButton 
          onClick={handleAIMode} 
          sx={{
            bgcolor: !AIEditorToggle ? "transparent" : "#fff",
            borderRadius: "2px",
            padding: "4px",
            height: "24px",
            width: "24px"
          }}
        >
          <Bot height="16px" width="16px" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Make changes via Editor" arrow placement="top">
        <IconButton 
          onClick={handleEditorMode} 
          sx={{
            bgcolor: AIEditorToggle ? "transparent" : "#fff",
            borderRadius: "2px",
            padding: "4px",
            height: "24px",
            width: "24px"
          }}
        >
          <PencilRuler height="16px" width="16px" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default AIEditorToggle;
