import { Box, Typography } from "@mui/material";
import { mapProfileData } from "..";
import { setProfileTabs } from "@/redux/slices/toolsSlice";
import { useTranslation } from "react-i18next";

const Sidebar = (props) => {
  const { dispatch, profileTabs } = props;
  const { t } = useTranslation();
  return (
    <Box sx={{ pt: "12px", pl: { xs: "0px", md: "0px", lg: "12px" } }}>
      {" "}
      {mapProfileData?.map((item, index) => (
        <Box
          key={index}
          onClick={() => {
            dispatch(setProfileTabs(index));
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "40px",
            cursor: "pointer",
            minWidth: {
              xs: "200px",
              sm: "180px",
              md: "190px",
              lg: "230px",
              xl: "240px",
            },
            backgroundColor: profileTabs === index ? "#FAFDFF" : "transparent",
            transition: "background-color 0.3s ease, transform 0.2s ease",
            ":hover": {
              backgroundColor: "#FAFDFF",
              transform: "translateX(4px)",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              transition: "transform 0.3s ease",
            }}
          >
            {/* <img
              src={item?.imgSrc}
              style={{
                height: "24px",
                width: "24px",
                marginRight: "12px",
                transition: "transform 0.3s ease",
                transform: profileTabs === index ? "scale(1.1)" : "scale(1)",
              }}
              alt="profile"
            /> */}
            {typeof item?.imgSrc === 'string' ? (
              <img
                src={item.imgSrc}
                alt={item.title}
                style={{
                  height: "24px",
                  width: "24px",
                  transition: "transform 0.3s ease",
                  transform: profileTabs === index ? "scale(1.1)" : "scale(1)",
                }}
              />
            ) : (
              item.imgSrc
            )}
            <Typography
              variant="subtitle1"
              sx={{
                marginLeft: "12px",
                color: profileTabs === index ? "#7C4CE1" : "#000",
                transition: "color 0.3s ease",
                fontWeight: profileTabs === index ? "500" : "normal",
              }}
            >
              {t(item?.title)}
            </Typography>
          </Box>

          <Box
            sx={{
              width: "3px",
              backgroundColor: "#7C4CE1",
              transition: "height 0.3s ease",
              height: profileTabs === index ? "100%" : "0%",
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default Sidebar;
