import PrimaryLoading from "@/components/loader/PrimaryLoading";
import { Box } from "@mui/material";

const LoadingProfile = () => {
  return (
    <Box sx={BoxStyle}>
      <PrimaryLoading />
    </Box>
  );
};

export default LoadingProfile;
const BoxStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  width: "100%",
};
