import React from 'react';

const PaymentForm = () => {
  const htmlContent = `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>CollectJS Payment Form</title>
    <style>
      body {
        font-family: inter, sans-serif;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        }
         .payment-form {
          width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
         }
        .form-group-container {
        width: 100%;
        display: flex;
        gap: 24px;
        }
      .form-group {
      width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
       
      label {
        display: block;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
      input[type="text"],
      #ccnumber,
      #ccexp,
      #cvv {
        width: 100%;
        border: 1px solid #DFE4EA;
        border-radius: 4px;
        height: 40px;
      }
      .payment-fields {
        display: flex;
        gap: 24px;
      }
      .payment-fields > div {
        flex: 1;
      }
      button {
        width: 100%;
        // padding: 10px;
        background-color: #4a90e2;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
        margin-top: 10px;
      }
      button:hover {
        background-color: #3a7bc8;
      }
      .token-display {
        margin-top: 5px;
        padding: 5px;
        background-color: #f5f5f5;
        border: 1px solid #ddd;
        border-radius: 4px;
      }
      .error-message {
        margin-top: 5px;
        padding: 5px;
        background-color: #ffebee;
        color: #c62828;
        border: 1px solid #ef9a9a;
        border-radius: 4px;
      }
      .success-message {
        margin-top: 5px;
        padding: 5px;
        background-color: #e8f5e9;
        color: #2e7d32;
        border: 1px solid #a5d6a7;
        border-radius: 4px;
      }
    </style>
    <!-- CollectJS Script -->
    <script
      src="https://secure.networkmerchants.com/token/Collect.js"
      data-tokenization-key="D4qndX-68zXK7-kh45D7-pU6F8n"
      data-variant="inline"
    ></script>
  </head>
  <body>
    <form id="payment-form" class="payment-form">
    <div class="form-group-container">
<div class="form-group">
        <label for="first_name">First Name</label>
        <input type="text" id="first_name" required />
      </div>

      <div class="form-group">
        <label for="last_name">Last Name</label>
        <input type="text" id="last_name" required />
      </div>
     </div>
      

      <div class="form-group">
        <label for="ccnumber">Credit Card Number</label>
        <div id="ccnumber"></div>
      </div>

      <div class="payment-fields">
        <div class="form-group">
          <label for="ccexp">Expiration Date</label>
          <div id="ccexp"></div>
        </div>

        <div class="form-group">
          <label for="cvv">CVV</label>
          <div id="cvv"></div>
        </div>
      </div>

      <button type="submit" id="payButton">Submit Payment</button>
    </form>

    <!-- Token Display Section -->
    <div id="token-display" class="token-display" style="display: none">
      <h4>Payment Token:</h4>
      <div style="display: flex; gap: 10px; align-items: center">
        <p id="token-value" style="flex-grow: 1; word-break: break-all"></p>
        <button
          id="copy-token"
          style="min-width: 100px; height: 36px; background-color: #4caf50"
        >
          Copy Token
        </button>
      </div>
    </div>

    <!-- Error Message Section -->
    <div id="error-message" class="error-message" style="display: none"></div>

    <script>
      // Store payment token
      let paymentToken = "";

      // Function to display token
      function displayToken(token) {
        const tokenDisplay = document.getElementById("token-display");
        const tokenValue = document.getElementById("token-value");

        tokenValue.textContent = token;
        tokenDisplay.style.display = "block";

        // Enable copy button functionality
        document
          .getElementById("copy-token")
          .addEventListener("click", function () {
            copyTokenToClipboard(token);
          });
      }

      // Function to copy token to clipboard
      function copyTokenToClipboard(token) {
        navigator.clipboard
          .writeText(token)
          .then(() => {
            // Show temporary "Copied!" feedback
            const copyBtn = document.getElementById("copy-token");
            const originalText = copyBtn.textContent;
            copyBtn.textContent = "Copied!";
            copyBtn.style.backgroundColor = "#2e7d32";

            setTimeout(() => {
              copyBtn.textContent = originalText;
              copyBtn.style.backgroundColor = "#4caf50";
            }, 2000);
          })
          .catch((err) => {
            displayError("Could not copy token: " + err);
          });
      }

      // Function to display error message
      function displayError(message) {
        const errorElement = document.getElementById("error-message");
        errorElement.textContent = message;
        errorElement.style.display = "block";

        // Hide error after 5 seconds
        setTimeout(() => {
          errorElement.style.display = "none";
        }, 5000);
      }

      // Wait for DOM to be fully loaded
      document.addEventListener("DOMContentLoaded", function () {
        // Configure CollectJS
        CollectJS.configure({
          fields: {
            ccnumber: {
              placeholder: "Credit Card Number",
              selector: "#ccnumber",
            },
            ccexp: {
              placeholder: "MM/YY",
              selector: "#ccexp",
            },
            cvv: {
              placeholder: "CVV",
              selector: "#cvv",
            },
          },
          callback: function (response) {
            console.log(response);
            // Save the token when payment info is complete
            if (response.token) {
              paymentToken = response.token;
              displayToken(paymentToken);
            }
          },
          validationCallback: function (field, status, message) {
            if (status === "invalid") {
               displayError(\`Error with \${field}: \${message}\`);
            }
          },
          errorCallback: function (message) {
            displayError("Payment Error: " + message);
          },
        });

        // Handle form submission
        document
          .getElementById("payment-form")
          .addEventListener("submit", function (e) {
            e.preventDefault();

            // Get form values
            const firstName = document.getElementById("first_name").value;
            const lastName = document.getElementById("last_name").value;
            const address = document.getElementById("address").value;

            // Check if payment token exists
            if (!paymentToken) {
              displayError("Please complete the payment information first");
              return;
            }

            // Log user details and token
            console.log("User Details:");
            console.log("First Name:", firstName);
            console.log("Last Name:", lastName);
            console.log("Address:", address);
            console.log("Payment Token:", paymentToken);

            // Create success message
            const successMessage = document.createElement("div");
            successMessage.className = "success-message";
            successMessage.textContent =
              "Payment information submitted successfully!";
            document.body.appendChild(successMessage);

            // This is where you would send data to your payment provider
            // Example:
            /*
            fetch('https://your-payment-endpoint.com/process', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                firstName: firstName,
                lastName: lastName,
                address: address,
                paymentToken: paymentToken
              }),
            })
            .then(response => response.json())
            .then(data => {
              console.log('Payment processed:', data);
            })
            .catch((error) => {
              console.error('Error:', error);
              displayError("Error processing payment: " + error.message);
            });
            */
          });
      });
    </script>
  </body>
</html>`;

  return ( 
      <iframe
        title="Payment Form"
        srcDoc={htmlContent}
        style={{ width: '100%', minHeight: "400px", border: "none" }}
        sandbox="allow-scripts allow-same-origin"
      />
  );
};

export default PaymentForm;