import DeleteModel from "@/components/model/DeleteModel";
import { useAppDispatch } from "@/redux/store";
import { deleteUserDetails } from "@/redux/thunk/userThunk";
import { Box, Button, Typography } from "@mui/material";

import React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const DeleteAccount = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const dispatch = useAppDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    const resultAction = await dispatch(deleteUserDetails());
    if (deleteUserDetails.fulfilled.match(resultAction)) {
      setOpen(false);
      localStorage.removeItem("persist:auth");
      toast.success(
        resultAction?.payload?.message || "User deleted Successfully."
      );
      setTimeout(() => {
        navigate("/");
        window.location.reload();
      }, 5);
    } else if (deleteUserDetails.rejected.match(resultAction)) {
      toast.error(resultAction?.payload?.reason || "Something went wronge");
    }
  };
  return (
    <Box sx={MainBoxStyle}>
      <Typography variant="body2">
        {t("Personal_Information.Delete_Account")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Typography variant="subtitle1" sx={{ my: { xs: "16px", md: "18px" } }}>
          {t("Personal_Information.It_is_important_to_note")}
        </Typography>
        <Button
          variant="outlined"
          sx={deleteButtonStyle}
          onClick={handleClickOpen}
        >
          {t("Personal_Information.Delete_Account")}
        </Button>
      </Box>
      <DeleteModel
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        open={open}
        title={t("Personal_Information.Delete_Account")}
        subTitle={t("Personal_Information.You_are_about_to_delete.")}
      />
    </Box>
  );
};

export default DeleteAccount;

const deleteButtonStyle = {
  borderColor: "#fff",
  width: { xs: "100%", sm: "150px" },
  marginRight: "8px",
  border: "2px solid #FF3B30",
  color: "#FF3B30",
  ":hover": {
    border: "2px solid #FF3B30",
    color: "#FF3B30",
    backgroundColor: "#ffe4ea",
  },
};
const MainBoxStyle = {
  mt: "24px",
  width: { xs: "100%", md: "760px", lg: "100%" },
  borderRadius: "8px",
  border: "1px solid #DFE4EA",
  boxShadow: " 0px 0px 4px 0px #E9E9E924",
  padding: { xs: "16px", sm: "20px", md: "24px" },
};
