import { Box } from "@mui/material";
import PrivateNavbar from "./navbar/PrivateNavbar";
import React from "react";
import { useAppSelector } from "@/redux/store";
import FullStackEditor from "./components/layout/FullStackEditor";
import BuilderNavbar from "./navbar/BuilderNavabr";

interface PrivatenavbarProps {
  children: React.ReactNode;
  customPadding?: string;
  location?: any;
}

const privateLayout: React.FC<PrivatenavbarProps> = (props) => {
  const { children, customPadding, location } = props;
  const mode = useAppSelector((state) => state.theme.mode);
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        bgcolor: mode === "dark" ? "#151c24" : "#fff",
        overflow: "hidden",
      }}
    >
      <Box sx={{ width: "100%", height: "100%", display: "flex" }}>
        {/* small sidebar  */}
        {location.pathname.startsWith("/builder-webApp") && <FullStackEditor />}

        <Box sx={{ width: "100%", height: "100vh" }}>
          {location.pathname.startsWith("/builder-webApp") ||
          location.pathname.startsWith("/icod-builder") ? (
            <BuilderNavbar location={location} />
          ) : (
            <PrivateNavbar location={location} />
          )}

          <Box
            sx={{
              height: "calc(100vh - 54px)",
              width: "100%",
              padding: customPadding ? customPadding : "0",
              overflow: "auto !important",
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default privateLayout;
