export const countriesList = [
  { label: "Select ", value: "none" },
  { label: "Afghanistan", code: "AF", value: "afghanistan" },
  { label: "Albania", code: "AL", value: "albania" },
  { label: "Algeria", code: "DZ", value: "algeria" },
  { label: "Andorra", code: "AD", value: "andorra" },
  { label: "Angola", code: "AO", value: "angola" },
  { label: "Antigua and Barbuda", code: "AG", value: "antigua and barbuda" },
  { label: "Argentina", code: "AR", value: "argentina" },
  { label: "Armenia", code: "AM", value: "armenia" },
  { label: "Australia", code: "AU", value: "australia" },
  { label: "Austria", code: "AT", value: "austria" },
  { label: "Azerbaijan", code: "AZ", value: "azerbaijan" },
  { label: "Bahamas", code: "BS", value: "bahamas" },
  { label: "Bahrain", code: "BH", value: "bahrain" },
  { label: "Bangladesh", code: "BD", value: "bangladesh" },
  { label: "Barbados", code: "BB", value: "barbados" },
  { label: "Belarus", code: "BY", value: "belarus" },
  { label: "Belgium", code: "BE", value: "belgium" },
  { label: "Belize", code: "BZ", value: "belize" },
  { label: "Benin", code: "BJ", value: "benin" },
  { label: "Bhutan", code: "BT", value: "bhutan" },
  { label: "Bolivia", code: "BO", value: "bolivia" },
  {
    label: "Bosnia and Herzegovina",
    code: "BA",
    value: "bosnia and herzegovina",
  },
  { label: "Botswana", code: "BW", value: "botswana" },
  { label: "Brazil", code: "BR", value: "brazil" },
  { label: "Brunei Darussalam", code: "BN", value: "brunei darussalam" },
  { label: "Bulgaria", code: "BG", value: "bulgaria" },
  { label: "Burkina Faso", code: "BF", value: "burkina faso" },
  { label: "Burundi", code: "BI", value: "burundi" },
  { label: "Cabo Verde", code: "CV", value: "cabo verde" },
  { label: "Cambodia", code: "KH", value: "cambodia" },
  { label: "Cameroon", code: "CM", value: "cameroon" },
  { label: "Canada", code: "CA", value: "canada" },
  {
    label: "Central African Republic",
    code: "CF",
    value: "central african republic",
  },
  { label: "Chad", code: "TD", value: "chad" },
  { label: "Chile", code: "CL", value: "chile" },
  { label: "China", code: "CN", value: "china" },
  { label: "Colombia", code: "CO", value: "colombia" },
  { label: "Comoros", code: "KM", value: "comoros" },
  { label: "Congo", code: "CG", value: "congo" },
  {
    label: "Congo, Democratic Republic of the",
    code: "CD",
    value: "congo, democratic republic of the",
  },
  { label: "Costa Rica", code: "CR", value: "costa rica" },
  { label: "Croatia", code: "HR", value: "croatia" },
  { label: "Cuba", code: "CU", value: "cuba" },
  { label: "Cyprus", code: "CY", value: "cyprus" },
  { label: "Czech Republic", code: "CZ", value: "czech republic" },
  { label: "Denmark", code: "DK", value: "denmark" },
  { label: "Djibouti", code: "DJ", value: "djibouti" },
  { label: "Dominica", code: "DM", value: "dominica" },
  { label: "Dominican Republic", code: "DO", value: "dominican republic" },
  { label: "Ecuador", code: "EC", value: "ecuador" },
  { label: "Egypt", code: "EG", value: "egypt" },
  { label: "El Salvador", code: "SV", value: "el salvador" },
  { label: "Equatorial Guinea", code: "GQ", value: "equatorial guinea" },
  { label: "Eritrea", code: "ER", value: "eritrea" },
  { label: "Estonia", code: "EE", value: "estonia" },
  { label: "Eswatini", code: "SZ", value: "eswatini" },
  { label: "Ethiopia", code: "ET", value: "ethiopia" },
  { label: "Fiji", code: "FJ", value: "fiji" },
  { label: "Finland", code: "FI", value: "finland" },
  { label: "France", code: "FR", value: "france" },
  { label: "Gabon", code: "GA", value: "gabon" },
  { label: "Gambia", code: "GM", value: "gambia" },
  { label: "Georgia", code: "GE", value: "georgia" },
  { label: "Germany", code: "DE", value: "germany" },
  { label: "Ghana", code: "GH", value: "ghana" },
  { label: "Greece", code: "GR", value: "greece" },
  { label: "Grenada", code: "GD", value: "grenada" },
  { label: "Guatemala", code: "GT", value: "guatemala" },
  { label: "Guinea", code: "GN", value: "guinea" },
  { label: "Guinea-Bissau", code: "GW", value: "guinea-bissau" },
  { label: "Guyana", code: "GY", value: "guyana" },
  { label: "Haiti", code: "HT", value: "haiti" },
  { label: "Honduras", code: "HN", value: "honduras" },
  { label: "Hungary", code: "HU", value: "hungary" },
  { label: "Iceland", code: "IS", value: "iceland" },
  { label: "India", code: "IN", value: "india" },
  { label: "Indonesia", code: "ID", value: "indonesia" },
  { label: "Iran", code: "IR", value: "iran" },
  { label: "Iraq", code: "IQ", value: "iraq" },
  { label: "Ireland", code: "IE", value: "ireland" },
  { label: "Israel", code: "IL", value: "israel" },
  { label: "Italy", code: "IT", value: "italy" },
  { label: "Jamaica", code: "JM", value: "jamaica" },
  { label: "Japan", code: "JP", value: "japan" },
  { label: "Jordan", code: "JO", value: "jordan" },
  { label: "Kazakhstan", code: "KZ", value: "kazakhstan" },
  { label: "Kenya", code: "KE", value: "kenya" },
  { label: "Kiribati", code: "KI", value: "kiribati" },
  {
    label: "Korea, Democratic People's Republic of",
    code: "KP",
    value: "korea, democratic people's republic of",
  },
  { label: "Korea, Republic of", code: "KR", value: "korea, republic of" },
  { label: "Kuwait", code: "KW", value: "kuwait" },
  { label: "Kyrgyzstan", code: "KG", value: "kyrgyzstan" },
  {
    label: "Lao People's Democratic Republic",
    code: "LA",
    value: "lao people's democratic republic",
  },
  { label: "Latvia", code: "LV", value: "latvia" },
  { label: "Lebanon", code: "LB", value: "lebanon" },
  { label: "Lesotho", code: "LS", value: "lesotho" },
  { label: "Liberia", code: "LR", value: "liberia" },
  { label: "Libya", code: "LY", value: "libya" },
  { label: "Liechtenstein", code: "LI", value: "liechtenstein" },
  { label: "Lithuania", code: "LT", value: "lithuania" },
  { label: "Luxembourg", code: "LU", value: "luxembourg" },
  { label: "Madagascar", code: "MG", value: "madagascar" },
  { label: "Malawi", code: "MW", value: "malawi" },
  { label: "Malaysia", code: "MY", value: "malaysia" },
  { label: "Maldives", code: "MV", value: "maldives" },
  { label: "Mali", code: "ML", value: "mali" },
  { label: "Malta", code: "MT", value: "malta" },
  { label: "Marshall Islands", code: "MH", value: "marshall islands" },
  { label: "Mauritania", code: "MR", value: "mauritania" },
  { label: "Mauritius", code: "MU", value: "mauritius" },
  { label: "Mexico", code: "MX", value: "mexico" },
  { label: "Micronesia", code: "FM", value: "micronesia" },
  { label: "Moldova", code: "MD", value: "moldova" },
  { label: "Monaco", code: "MC", value: "monaco" },
  { label: "Mongolia", code: "MN", value: "mongolia" },
  { label: "Montenegro", code: "ME", value: "montenegro" },
  { label: "Morocco", code: "MA", value: "morocco" },
  { label: "Mozambique", code: "MZ", value: "mozambique" },
  { label: "Myanmar", code: "MM", value: "myanmar" },
  { label: "Namibia", code: "NA", value: "namibia" },
  { label: "Nauru", code: "NR", value: "nauru" },
  { label: "Nepal", code: "NP", value: "nepal" },
  { label: "Netherlands", code: "NL", value: "netherlands" },
  { label: "New Zealand", code: "NZ", value: "new zealand" },
  { label: "Nicaragua", code: "NI", value: "nicaragua" },
  { label: "Niger", code: "NE", value: "niger" },
  { label: "Nigeria", code: "NG", value: "nigeria" },
  { label: "North Macedonia", code: "MK", value: "north macedonia" },
  { label: "Norway", code: "NO", value: "norway" },
  { label: "Oman", code: "OM", value: "oman" },
  { label: "Pakistan", code: "PK", value: "pakistan" },
  { label: "Palau", code: "PW", value: "palau" },
  { label: "Palestine", code: "PS", value: "palestine" },
  { label: "Panama", code: "PA", value: "panama" },
  { label: "Papua New Guinea", code: "PG", value: "papua new guinea" },
  { label: "Paraguay", code: "PY", value: "paraguay" },
  { label: "Peru", code: "PE", value: "peru" },
  { label: "Philippines", code: "PH", value: "philippines" },
  { label: "Poland", code: "PL", value: "poland" },
  { label: "Portugal", code: "PT", value: "portugal" },
  { label: "Qatar", code: "QA", value: "qatar" },
  { label: "Romania", code: "RO", value: "romania" },
  { label: "Russian Federation", code: "RU", value: "russian federation" },
  { label: "Rwanda", code: "RW", value: "rwanda" },
  {
    label: "Saint Kitts and Nevis",
    code: "KN",
    value: "saint kitts and nevis",
  },
  { label: "Saint Lucia", code: "LC", value: "saint lucia" },
  {
    label: "Saint Vincent and the Grenadines",
    code: "VC",
    value: "saint vincent and the grenadines",
  },
  { label: "Samoa", code: "WS", value: "samoa" },
  { label: "San Marino", code: "SM", value: "san marino" },
  {
    label: "Sao Tome and Principe",
    code: "ST",
    value: "sao tome and principe",
  },
  { label: "Saudi Arabia", code: "SA", value: "saudi arabia" },
  { label: "Senegal", code: "SN", value: "senegal" },
  { label: "Serbia", code: "RS", value: "serbia" },
  { label: "Seychelles", code: "SC", value: "seychelles" },
  { label: "Sierra Leone", code: "SL", value: "sierra leone" },
  { label: "Singapore", code: "SG", value: "singapore" },
  { label: "Slovakia", code: "SK", value: "slovakia" },
  { label: "Slovenia", code: "SI", value: "slovenia" },
  { label: "Solomon Islands", code: "SB", value: "solomon islands" },
  { label: "Somalia", code: "SO", value: "somalia" },
  { label: "South Africa", code: "ZA", value: "south africa" },
  { label: "South Sudan", code: "SS", value: "south sudan" },
  { label: "Spain", code: "ES", value: "spain" },
  { label: "Sri Lanka", code: "LK", value: "sri lanka" },
  { label: "Sudan", code: "SD", value: "sudan" },
  { label: "Surilabel", code: "SR", value: "surilabel" },
  { label: "Sweden", code: "SE", value: "sweden" },
  { label: "Switzerland", code: "CH", value: "switzerland" },
  { label: "Syrian Arab Republic", code: "SY", value: "syrian arab republic" },
  { label: "Taiwan", code: "TW", value: "taiwan" },
  { label: "Tajikistan", code: "TJ", value: "tajikistan" },
  { label: "Tanzania", code: "TZ", value: "tanzania" },
  { label: "Thailand", code: "TH", value: "thailand" },
  { label: "Timor-Leste", code: "TL", value: "timor-leste" },
  { label: "Togo", code: "TG", value: "togo" },
  { label: "Tonga", code: "TO", value: "tonga" },
  { label: "Trinidad and Tobago", code: "TT", value: "trinidad and tobago" },
  { label: "Tunisia", code: "TN", value: "tunisia" },
  { label: "Turkey", code: "TR", value: "turkey" },
  { label: "Turkmenistan", code: "TM", value: "turkmenistan" },
  { label: "Tuvalu", code: "TV", value: "tuvalu" },
  { label: "Uganda", code: "UG", value: "uganda" },
  { label: "Ukraine", code: "UA", value: "ukraine" },
  { label: "United Arab Emirates", code: "AE", value: "united arab emirates" },
  { label: "United Kingdom", code: "GB", value: "united kingdom" },
  {
    label: "United States of America",
    code: "US",
    value: "united states of america",
  },
  { label: "Uruguay", code: "UY", value: "uruguay" },
  { label: "Uzbekistan", code: "UZ", value: "uzbekistan" },
  { label: "Vanuatu", code: "VU", value: "vanuatu" },
  { label: "Vatican City", code: "VA", value: "vatican city" },
  { label: "Venezuela", code: "VE", value: "venezuela" },
  { label: "Vietnam", code: "VN", value: "vietnam" },
  { label: "Yemen", code: "YE", value: "yemen" },
  { label: "Zambia", code: "ZM", value: "zambia" },
  { label: "Zimbabwe", code: "ZW", value: "zimbabwe" },
];

export const gendersList = [
  { label: "Select ", value: "none" },
  { label: "Male", code: "M", value: "male" },
  { label: "Female", code: "F", value: "female" },
  { label: "Other", code: "O", value: "other" },
  { label: "Prefer not to say", code: "PNS", value: "prefer_not_to_say" },
];

export const professionsList = [
  { label: "Select ", value: "none" },
  { label: "Software Engineer", code: "SE", value: "software engineer" },
  { label: "Data Scientist", code: "DS", value: "data scientist" },
  { label: "Product Manager", code: "PM", value: "product manager" },
  { label: "Graphic Designer", code: "GD", value: "graphic designer" },
  { label: "Teacher", code: "T", value: "teacher" },
  { label: "Doctor", code: "D", value: "doctor" },
  { label: "Nurse", code: "N", value: "nurse" },
  { label: "Lawyer", code: "L", value: "lawyer" },
  { label: "Accountant", code: "A", value: "accountant" },
  { label: "Other", code: "O", value: "other" },
];

export const languagesList = [
  { label: "Select ", value: "none" },
  { label: "English", code: "EN", value: "english" },
  { label: "Spanish", code: "ES", value: "spanish" },
  { label: "French", code: "FR", value: "french" },
  { label: "German", code: "DE", value: "german" },
  { label: "Chinese", code: "ZH", value: "chinese" },
  { label: "Arabic", code: "AR", value: "arabic" },
  { label: "Russian", code: "RU", value: "russian" },
  { label: "Japanese", code: "JA", value: "japanese" },
  { label: "Italian", code: "IT", value: "italian" },
  { label: "Portuguese", code: "PT", value: "portuguese" },
  { label: "Hindi", code: "HI", value: "hindi" },
  { label: "Korean", code: "KO", value: "korean" },
  { label: "Turkish", code: "TR", value: "turkish" },
  { label: "Dutch", code: "NL", value: "dutch" },
  { label: "Swedish", code: "SV", value: "swedish" },
  { label: "Other", code: "OT", value: "other" },
];
