import { Button } from "@mui/material";
import PrimaryLoading from "../loader/PrimaryLoading";
interface OutlinedButtonProps {
    text: string;
    style?: object;
    imageComponent?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    loading?: boolean;
    compLeft?: boolean;
    disable?: boolean;
    disabled?: boolean;
}

const OutlinedButton: React.FC<OutlinedButtonProps> = (props) => {
    const { text, style, imageComponent, onClick, loading, compLeft, disabled } =
        props;
    return (
        <Button
            type="submit"
            variant="outlined"
            disableFocusRipple={true}
            onClick={onClick}
            disabled={loading || disabled}
            sx={{
                ...style,
                backgroundColor: "#FFFFFF",
                transition: " 0.5s",
                textTransform: "none",
                border: "1px solid #7C4CE1",
                color: "#7C4CE1",
                backgroundSize: " 200% auto",
                height: "40px",
                "&:disabled": {
                    background: "#cccccc",
                    cursor: "not-allowed",
                },
            }}
        >
            {loading ? (
                <PrimaryLoading />
            ) : (
                <>
                    {compLeft === true && imageComponent && imageComponent} {text}{" "}
                    {imageComponent && compLeft === false && imageComponent}
                </>
            )}
        </Button>
    );
};

export default OutlinedButton;
