import { matchIsValidTel } from "mui-tel-input";
import * as yup from "yup";
import { disposableEmailDomains } from "./constants.util";

export const loginSchema = () =>
  yup.object({
    email: yup.string().required("Email is required"),
    password: yup.string().required("Password is required"),
  });

export const SignupSchema = () =>
  yup.object({
    fullName: yup
      .string()
      .required("Full Name is required")
      .matches(/^[a-zA-Z ]+$/, "Full Name can only contain letters and spaces")
      .max(100, "Full Name cannot exceed 100 characters"),
    email: yup
      .string()
      .required("Email is required")
      .email("Must be a valid email")
      .test(
        "not-disposable-email",
        "Disposable email addresses are not allowed",
        (value) => {
          if (!value) return true; // Skip validation if empty (handled by 'required')
          const domain = value.split("@")[1];
          return !disposableEmailDomains.includes(domain);
        }
      )
      .test("valid-email-domain", "Must be a valid email", (value) =>
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value || "")
      ),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .test({
        name: "has-uppercase",
        message: "Password must contain at least one uppercase letter",
        test: function (value) {
          return /[A-Z]/.test(value);
        },
      })
      .test({
        name: "has-lowercase",
        message: "Password must contain at least one lowercase letter",
        test: function (value) {
          return /[a-z]/.test(value);
        },
      })
      .test({
        name: "has-number",
        message: "Password must contain at least one number",
        test: function (value) {
          return /\d/.test(value);
        },
      })
      .test({
        name: "has-special-char",
        message: "Password must contain at least one  special character",
        test: function (value) {
          return /[!@#$%^&*()_+]/.test(value);
        },
      }),
  });

export const ForgetPasswordSchema = () =>
  yup.object({
    email: yup
      .string()
      .required("Email is required")
      .email("Must be a valid email"),
  });
export const resestPasswordSchema = () =>
  yup.object().shape({
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .test({
        name: "has-uppercase",
        message: "Password must contain at least one uppercase letter",
        test: function (value) {
          return /[A-Z]/.test(value);
        },
      })
      .test({
        name: "has-lowercase",
        message: "Password must contain at least one lowercase letter",
        test: function (value) {
          return /[a-z]/.test(value);
        },
      })
      .test({
        name: "has-number",
        message: "Password must contain at least one number",
        test: function (value) {
          return /\d/.test(value);
        },
      })
      .test({
        name: "has-special-char",
        message: "Password must contain at least one  special character",
        test: function (value) {
          return /[!@#$%^&*()_+]/.test(value);
        },
      }),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Both password need to be the same")
      .required("Confirm password is required"),
  });

export const promptSchema = () =>
  yup.object({
    prompt: yup
      .string()
      .required("Prompt is required")
      .min(10, "Prompt must be at least 10 characters long")
      .max(500, "Prompt must be at most 500 characters long"),
    projectName: yup.string().required("Project name is required."),
    categories: yup
      .array()
      .min(1, "Select at least 1 design styles")
      .max(1, "Select at most 1 design styles")
      .required("Design Style is required"),
  });

export const collaboratorsSchema = () =>
  yup.object({
    email: yup
      .string()
      .email("Must be a valid email")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Must be a valid email"
      ),
  });
export const editToolSchema = () =>
  yup.object({
    prompt: yup
      .string()
      .required("Prompt is required")
      .max(500, "Prompt must be at most 500 characters long"),
  });

export const ChangePasswordSchema = () =>
  yup.object().shape({
    currentPassword: yup.string().required("Current password is required"),
    newPassword: yup
      .string()
      .required("New password is required")
      .min(8, "Password must be at least 8 characters")
      .test({
        name: "has-uppercase",
        message: "Password must contain at least one uppercase letter",
        test: function (value) {
          return /[A-Z]/.test(value);
        },
      })
      .test({
        name: "has-lowercase",
        message: "Password must contain at least one lowercase letter",
        test: function (value) {
          return /[a-z]/.test(value);
        },
      })
      .test({
        name: "has-number",
        message: "Password must contain at least one number",
        test: function (value) {
          return /\d/.test(value);
        },
      })
      .test({
        name: "has-special-char",
        message: "Password must contain at least one  special character",
        test: function (value) {
          return /[!@#$%^&*()_+]/.test(value);
        },
      }),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword")], "Both password need to be the same")
      .required("Confirm password is required"),
  });

export const ProfileSchema = () =>
  yup.object({
    fullName: yup
      .string()
      .required("Full Name is required")
      .matches(
        /^[a-zA-Z0-9 ]+$/,
        "Full Name can only contain letters and numbers"
      )
      .max(100, "Full Name cannot exceed 100 characters"),
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
    gender: yup
      .string()
      .test(
        "is-gender-selected",
        "Please select a gender",
        (value) => value !== "none"
      )
      .required("Gender is required"),
    profession: yup
      .string()
      .test(
        "is-profession-selected",
        "Please select a profession",
        (value) => value !== "none"
      )
      .required("Profession is required"),
    country: yup
      .string()
      .test(
        "is-country-selected",
        "Please select a country",
        (value) => value !== "none"
      )
      .required("Country is required"),
    preferredLanguage: yup
      .string()
      .test(
        "is-language-selected",
        "Please select a language",
        (value) => value !== "none"
      )
      .required("Preferred Language is required"),
    contactNumber: yup
      .string()
      .test("valid-phone", "Invalid phone number", (value) => {
        // Validate phone number format and ensure there are digits after the country code
        return matchIsValidTel(value) && /\+\d{1,4}\s?\d{2,}/.test(value);
      })
      .required("Contact number is required"),
    dateOfBirth: yup
      .date()
      .nullable()
      .typeError("Invalid date format")
      .required("Date of birth is required"),
  });

export const CreateAppSchema = () =>
  yup.object({
    prompt: yup
      .string()
      .required("Prompt is required")
      .min(10, "Prompt must be at least 10 characters long")
      .test(
        "word-count",
        "Prompt must be at most 500 words long",
        function (value) {
          if (!value) return true; // Skip validation if field is empty (handled by required)
          const wordCount = value.trim().split(/\s+/).length;
          return wordCount <= 500;
        }
      ),
    projectName: yup
      .string()
      .required("Project name is required.")
      .matches(
        /^[a-zA-Z0-9 ]+$/,
        "Project name can only contain letters, numbers, and spaces"
      ),
  });

export const EmailCredentialsSchema = () =>
  yup.object({
    appPassword: yup.string().required("App password Required"),
    email: yup
      .string()
      .required("Email is required")
      .email("Must be a valid email")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Must be a valid email"
      ),
    receiverEmail: yup
      .string()
      .email("Must be a valid email")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Must be a valid email"
      ),
  });

// Domain validation schema using Yup
export const domainValidationSchema = yup.object({
  customDomain: yup
    .string()
    .required("Domain is required")
    .min(3, "Domain must be at least 3 characters")
    .max(50, "Domain cannot exceed 50 characters")
    .test(
      "no-special-chars",
      "Special characters are not allowed except for hyphens and periods",
      (value) => {
        if (!value) return true; // Skip if empty (handled by required)
        return !/[^a-zA-Z0-9\-\.]/.test(value); // Fail if special chars exist
      }
    ),
});
