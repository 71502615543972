import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

interface getChatbotHistoryResponse {
  message: string;
  response: { 
    messages: any,

  }; 
   snapshot:any 
}
interface Chatbot_API_Fun_Response {
  message: string;
  response: any;
}

export const getChatbotHistory = createAsyncThunk<
  getChatbotHistoryResponse,
  {
    project_id: string;
  },
  { rejectValue: any }
>(
  "chatbot/get_history",
  async (
    data: {
      project_id: string;
    },
    thunkAPI
  ) => {
    try {
      const response = await axios.get(
        `${
          import.meta.env.VITE_APP_AI_CHATBOT_URL
        }chatbot_static_history?project_id=${data?.project_id}`
      );
      return response.data; // Return the response if successful
    } catch (error: any) {
      // Error handling
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "An error occurred"
      );
    }
  }
);

export const Chatbot_API_Fun = createAsyncThunk<
  Chatbot_API_Fun_Response,
  {
    user_prompt: string;
    project_id: string;
    website_code: any;
    code_snippet: any;
  },
  { rejectValue: any }
>(
  "chatbot/chat_with_chatbot",
  async (
    data: {
      user_prompt: string;
      project_id: string;
      website_code: any;
      code_snippet: any;
    },
    thunkAPI
  ) => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_APP_AI_CHATBOT_URL}chatbot_static_v1`,
        data
      );
      return response.data; // Return the response if successful
    } catch (error: any) {
      // Error handling
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "An error occurred"
      );
    }
  }
);
