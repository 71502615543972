import { Button } from "@mui/material";
import PrimaryLoading from "../loader/PrimaryLoading";
interface PrimaryButtonProps {
  text: string;
  style?: object;
  imageComponent?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  loading?: boolean;
  compLeft?: boolean;
  disable?: boolean;
  disabled?: boolean;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = (props) => {
  const { text, style, imageComponent, onClick, loading, compLeft, disabled } =
    props;
  return (
    <Button
      type="submit"
      variant="contained"
      disableFocusRipple={true}
      onClick={onClick}
      disabled={loading || disabled}
      sx={{
        ...style,
        backgroundColor: "#7c4ce1",
        transition: " 0.5s",
        textTransform: "none",
        backgroundSize: " 200% auto",
        minHeight: "36px",
        "&:disabled": {
          background: "#cccccc",
          cursor: "not-allowed",
        },
      }}
    >
      {loading ? (
        <PrimaryLoading />
      ) : (
        <>
          {compLeft === true && imageComponent && imageComponent} {text}{" "}
          {imageComponent && compLeft === false && imageComponent}
        </>
      )}
    </Button>
  );
};

export default PrimaryButton;
