import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { X } from "lucide-react";
import VisaCard from "../../../assets/images/VISA.svg";
import MasterCard from "../../../assets/images/Mastercard.svg";
import AmexCard from "../../../assets/images/AMEX.svg";
import DiscoverCard from "../../../assets/images/Discover.svg";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import PaymentForm from "./PaymentForm";

const PaymentModal = ({ open, onClose, plan, type }) => {
    if (!open) return null;
    const { t } = useTranslation();

    return (
        <Box sx={overlayStyle} >
            <Box sx={modalStyle} onClick={(e) => e.stopPropagation()}>
                <Box sx={CardHeaderContainer}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography variant='h6'>
                            Confirm Payment
                        </Typography>
                        <Button onClick={onClose} sx={{ width: "40px", height: "40px", }}>
                            <X height={20} width={20} />
                        </Button>

                    </Box>

                    <Box sx={CardsContainer}>
                        <img
                            alt='VISA'
                            src={VisaCard}
                            style={{ height: "24px", width: "36px" }}
                        />
                        <img
                            alt='Master'
                            src={MasterCard}
                            style={{ height: "24px", width: "36px" }}
                        />
                        <img
                            alt='AMEX'
                            src={AmexCard}
                            style={{ height: "24px", width: "36px" }}
                        />
                        <img
                            alt='Discover'
                            src={DiscoverCard}
                            style={{ height: "24px", width: "36px" }}
                        />
                    </Box>
                </Box>
                <Box sx={PlanContainer}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column", gap: "4px"
                    }}>
                        <Typography
                            variant="h3"
                            sx={{
                                fontSize: "30px",
                                color: "#1E293B",
                            }}
                        >
                            {plan?.name}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            sx={{ color: "#667085" }}
                        >
                            {plan?.description}
                        </Typography>
                    </Box>
                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: "48px",
                            color: "#1E293B",
                        }}
                    >$
                        {type == 0 ? plan?.monthlyPrice : plan?.yearlyPrice}
                        <span style={{ fontSize: "16px", fontWeight: "500", color: "#667085" }}>{type == 0 ? "/mo" : "/Year"}</span>
                    </Typography>
                </Box>
                <PaymentForm />
                <Box sx={{ display: "flex", justifyContent: "end", gap: "8px" }}>
                    <Button sx={{
                        width: "fit-content", padding: "8px 14px", backgroundColor: "#FFFFFF", borderRadius: "6px", display: "flex", alignItems: "center", justifyContent: "center", border: "1px solid #7C4CE1", ":hover": {
                            backgroundColor: "#FFFFFF",
                        },
                    }}>
                        <Typography variant="caption" sx={{ color: "#7C4CE1", fontWeight: "500" }}>
                            Back
                        </Typography>
                    </Button>
                    <PrimaryButton
                        // loading={loading}
                        style={{
                            width: { xs: "100%", sm: "auto" },
                            minWidth: "90px",
                            height: "30px"
                        }}
                        compLeft={true}
                        text={"Confirm"}
                    />

                </Box>
            </Box>
        </Box>
    );
}

export default PaymentModal;

const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1110,
};

const modalStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "40px",
    padding: "32px",
    borderRadius: "20px",
    backgroundColor: "#FFFFFF", // Example background color
    width: "600px",
};

const CardHeaderContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "16px"

}

const CardsContainer = {
    display: "flex",
    gap: "8px"
}

const PlanContainer = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
}