import {
  saveingEditorIcon,
  unSaveEditorIcon,
} from "@/assets/images";
import { setSaveCode } from "@/redux/slices/toolsSlice";
import { Box, Typography } from "@mui/material";

export const SaveFuncationality = (props) => {
  const { saveProjectLoader, saveProjectStatus, showSaving, t, dispatch } =
    props;
  return (
    <Box sx={{ ml: "12px", transition: "all .4s" }} className="icod-share-and-collborator-button-toolbar-controls">
      {saveProjectLoader ? (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              width: "18px",
              aspectRatio: "1", // Maintain a 1:1 aspect ratio
              borderRadius: "50%",
              border: "3px solid #D9D9D9",
              borderRightColor: "#7a4ddf",
              animation: "rotateAnimation 1s infinite linear",
              "@keyframes rotateAnimation": {
                to: {
                  transform: "rotate(1turn)", // Equivalent to 360deg
                },
              },
            }}
          />
        </Box>
      ) : saveProjectStatus && !saveProjectLoader ? (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img
            src={saveingEditorIcon}
            alt="save"
            style={{ cursor: "pointer" }}
          />
          {showSaving && (
            <Typography
              variant="subtitle1"
              sx={{ color: "#1E293B", pt: "2.7px", ml: "6px" }}
            >
              {t("Header.Changes_saved")}
            </Typography>
          )}
        </Box>
      ) : (
        <img
          src={unSaveEditorIcon}
          alt="save"
          style={{ cursor: "pointer" }}
          onClick={() => {
            dispatch(setSaveCode(true));
          }}
        />
      )}
    </Box>
  );
};

export default SaveFuncationality;
