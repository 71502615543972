import axios from "axios";
import http from "../../services/http.service";
import { createAsyncThunk } from "@reduxjs/toolkit";

interface getProjectsResponse {
  message: string;
  projects: any[];
}
interface ImageUploadResponse {
  message: string;
  url: string;
  caption?: string;
}
interface updateProjectNameResponse {
  message: string;
  response: any;
}
interface prjectTypeAndNameResponse {
  message: string;
  response: any;
}
interface prjectCoreFeaturesResponse {
  message: string;
  response: any;
}
interface deleteProjectResponse {
  message: string;
  response: any;
}
interface promptEnhancerResponse {
  message: string;
  response: any;
}
interface UpdateCredientialsResponse {
  message: string;
  emailService: any;
}
interface getCredientialsResponse {
  message: string;
  emailService: any;
}
interface editFullStackAppResponse {
  message: string;
  payload: any;
  project: any;
}

export const getProjects = createAsyncThunk<
  getProjectsResponse,
  {
    shared?: boolean;
    limit: Number;
    offset?: Number;
    type: String;
    name: String;
  },
  { rejectValue: any }
>(
  "project/all",
  async (
    data: {
      shared?: boolean;
      limit: Number;
      offset?: Number;
      type?: String;
      name?: String;
    },
    thunkAPI
  ) => {
    // Construct query parameters dynamically
    const queryParams = new URLSearchParams();

    if (typeof data?.shared === "boolean") {
      queryParams.append("shared", `${data?.shared}`); // Implicit string conversion
    }

    queryParams.append("limit", `${data?.limit ?? 10}`);
    queryParams.append("offset", `${data?.offset ?? 0}`);

    if (data?.type) queryParams.append("type", `${data?.type}`);
    if (data?.name) queryParams.append("name", `${name}`);
    const [response, error] = await http.get(
      `/project/all?${queryParams.toString()}`
    );
    if (error) {
      return thunkAPI.rejectWithValue(error.resposne.data);
    }
    return response.data;
  }
);

export const imagePost = createAsyncThunk<
  ImageUploadResponse,
  { image: File; projectId: string },
  { rejectValue: any }
>(
  "upload/uploadImage",
  async (data: { image: File; projectId: string }, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append("image", data.image);
      const [response, error] = await http.post(
        `/upload/uploadImage/${data?.projectId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }

      return response.data as ImageUploadResponse;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data || "Error uploading image"
      );
    }
  }
);

export const updateProjectName = createAsyncThunk<
  updateProjectNameResponse,
  {
    projectName: string;
    projectId: string;
  },
  { rejectValue: any }
>(
  "updateProjectName",
  async (
    data: {
      projectName: string;
      projectId: string;
    },
    thunkAPI
  ) => {
    const [response, error] = await http.patch(
      `/project//update/projectName/${data.projectId}`,
      { projectName: data.projectName }
    );
    if (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
    return response.data;
  }
);

export const prjectTypeAndName = createAsyncThunk<
  prjectTypeAndNameResponse,
  {
    prompt: string;
  },
  { rejectValue: any }
>(
  "project/prjectTypeAndName",
  async (
    data: {
      prompt: string;
    },
    thunkAPI
  ) => {
    const [response, error] = await http.post(`/project/analyzePrompt`, data);
    if (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
    return response.data;
  }
);

export const prjectCoreFeatures = createAsyncThunk<
  prjectCoreFeaturesResponse,
  {
    prompt: string;
  },
  { rejectValue: any }
>(
  "project/prjectCoreFeatures",
  async (
    data: {
      prompt: string;
    },
    thunkAPI
  ) => {
    const [response, error] = await http.post(
      `/project/analyzePrompt/Features`,
      data
    );
    if (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
    return response.data;
  }
);
export const promptEnhancer = createAsyncThunk<
  promptEnhancerResponse,
  {
    prompt: string;
  },
  { rejectValue: any }
>(
  "project/promptEnhancer",
  async (
    data: {
      prompt: string;
    },
    thunkAPI
  ) => {
    const [response, error] = await http.post(`/project/promptEnhancer`, data);
    if (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
    return response.data;
  }
);

export const deleteProject = createAsyncThunk<
  deleteProjectResponse,
  {
    projectId: string;
  },
  { rejectValue: any }
>(
  "project/deletetheproject",
  async (
    data: {
      projectId: string;
    },
    thunkAPI
  ) => {
    const [response, error] = await http.delete(
      `/project/delete/${data.projectId}`
    );
    if (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
    return response.data;
  }
);

export const editFullStackApp = createAsyncThunk<
  editFullStackAppResponse,
  {
    prompt: string;
    project_id: string;
    component_key: string;
  },
  { rejectValue: any }
>(
  "project/editFullstack",
  async (
    data: {
      prompt: string;
      project_id: string;
      component_key: string;
    },
    thunkAPI
  ) => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_APP_BASE_URL_DEV_NEST}projects/edit_fullstack`,
        data
      );
      return response.data; // Return the response if successful
    } catch (error: any) {
      // Error handling
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "An error occurred"
      );
    }
  }
);

export const UpdateCredientials = createAsyncThunk<
  UpdateCredientialsResponse,
  {
    email: string;
    appPassword: string;
    receiverEmail: string;
    projectId: string;
  },
  { rejectValue: any }
>(
  "project/EmailCredentials",
  async (
    data: {
      email: string;
      appPassword: string;
      receiverEmail: string;
      projectId: string;
    },
    thunkAPI
  ) => {
    const [response, error] = await http.patch(
      `/project/update/email-service/${data.projectId}`,
      {
        email: data.email,
        appPassword: data.appPassword,
        receiverEmail: data.receiverEmail,
      }
    );
    if (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
    return response.data;
  }
);

export const getCredientials = createAsyncThunk<
  getCredientialsResponse,
  {
    projectId: string;
  },
  { rejectValue: any }
>(
  "project/get/EmailCredentials",
  async (
    data: {
      projectId: string;
    },
    thunkAPI
  ) => {
    const [response, error] = await http.get(
      `/project/email-service/${data.projectId}`
    );
    if (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
    return response.data;
  }
);
