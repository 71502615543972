import PrimaryButton from "@/components/buttons/PrimaryButton";
import PrimaryTextField from "@/components/textField/PrimaryTextField";
import TextFieldSelect from "@/components/textField/TextFieldSelect";
import {
  Box,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import dayjs from "dayjs";
import {
  countriesList,
  gendersList,
  languagesList,
  professionsList,
} from "./mappingData";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/redux/store";
import LoadingProfile from "./LoadingProfile";

const PersonalInfo = (props) => {
  const { formik } = props;
  const { loading } = useAppSelector((state) => state.user);
  const { t } = useTranslation();
  const {
    values,
    handleBlur,
    handleChange,
    touched,
    errors,
    setFieldValue,
    handleSubmit,
  } = formik;

  return (
    <Box component={"form"} onSubmit={handleSubmit} sx={FormBoxStyle}>
      {loading ? (
        <Box
          sx={{ height: { xs: "939.66px", sm: "939.66px", md: "515.89px" } }}
        >
          <LoadingProfile />
        </Box>
      ) : (
        <Box>
          {" "}
          <Typography variant="body2" sx={{ mb: { xs: "16px", md: "18px" } }}>
            {t("Personal_Information.Personal_Information")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              {" "}
              <PrimaryTextField
                title={t("Sign_Up.Name")}
                placeholder={t("Sign_Up.Enter_Full_Name")}
                value={values?.fullName}
                name="fullName"
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched?.fullName}
                errors={errors.fullName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PrimaryTextField
                disabled={true}
                title={t("Login.Email")}
                placeholder={t("Login.Enter_email")}
                value={values?.email}
                name="email"
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched?.email}
                errors={errors.email}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldSelect
                title={t("Personal_Information.Gender")}
                value={values?.gender}
                name="gender"
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched?.gender}
                errors={errors.gender}
                mapData={gendersList}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" sx={{ pb: "6px", fontWeight: "500" }}>
                {t("Personal_Information.Date_of_birth")}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  views={["year", "month", "day"]}
                  value={values?.dateOfBirth || null}
                  onChange={(newValue) => {
                    if (newValue && dayjs(newValue).isValid()) {
                      setFieldValue(
                        "dateOfBirth",
                        dayjs(newValue).format("YYYY-MM-DD")
                      );
                    } else {
                      setFieldValue("dateOfBirth", null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="dateOfBirth"
                      sx={{
                        "& .MuiOutlinedInput-input": {
                          color: "#667085",
                          fontWeight: "400",
                        },
                      }}
                      onBlur={handleBlur}
                      fullWidth
                      error={Boolean(touched.dateOfBirth && errors.dateOfBirth)}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: t(
                          "Personal_Information.Select_Date_Of_Birth"
                        ),
                        "data-cy": "birth-personaldetail", // Cypress ID assigned to the search input element
                      }}
                    />
                  )}
                  PopperProps={{
                    sx: {
                      "& .css-leblpe-PrivatePickersYear-button": {
                        textAlign: "center",
                        // Add any other styles you want to apply to the year button
                      },
                    },
                  }}
                  maxDate={dayjs()}
                />
                <Box
                  sx={{
                    height: "20px",
                    width: "100%",
                    mt: "2px",
                  }}
                >
                  {touched.dateOfBirth && Boolean(errors.dateOfBirth) && (
                    <FormHelperText error sx={{ paddingLeft: "0", mt: 0 }}>
                      {touched.dateOfBirth && errors.dateOfBirth}
                    </FormHelperText>
                  )}
                </Box>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldSelect
                title={t("Personal_Information.Profession")}
                value={values?.profession}
                name="profession"
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched?.profession}
                errors={errors.profession}
                mapData={professionsList}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={PhoneBoxStyle}>
                <Typography variant="h6" sx={{ pb: "6px", fontWeight: "500" }}>
                  {t("Personal_Information.Contact_Number")}
                </Typography>
                <MuiTelInput
                  name="contactNumber"
                  value={formik.values.contactNumber}
                  onChange={(newPhone) =>
                    formik.setFieldValue("contactNumber", newPhone)
                  }
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-input": {
                      color: "#667085",
                      fontWeight: "400",
                    },
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.contactNumber &&
                    Boolean(formik.errors.contactNumber)
                  }
                  defaultCountry="PK"
                />
                {formik.touched.contactNumber &&
                  formik.errors.contactNumber && (
                    <Typography color="error" variant="caption">
                      {formik.errors.contactNumber}
                    </Typography>
                  )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldSelect
                title={t("Personal_Information.Country")}
                value={values?.country || ""}
                name="country"
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched?.country}
                errors={errors.country}
                mapData={countriesList}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldSelect
                title={t("Personal_Information.Preferred_Language")}
                value={values?.preferredLanguage}
                name="preferredLanguage"
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched?.preferredLanguage}
                errors={errors.preferredLanguage}
                mapData={languagesList}
              />
            </Grid>
          </Grid>
          <Box sx={PrimaryBtnStye}>
            <PrimaryButton
              style={{
                width: { xs: "100%", md: "160px" },
              }}
              compLeft={true}
              text={t("Personal_Information.Save_Changes")}
              onClick={() => {
                // Manually validate the form, including contact number
                formik.validateForm().then(() => {
                  if (
                    !values?.contactNumber ||
                    !matchIsValidTel(values.contactNumber)
                  ) {
                    setFieldValue("contactNumberError", false); // Trigger error for invalid phone
                  } else {
                    setFieldValue("contactNumberError", true); // Clear the error
                  }

                  formik.handleSubmit(); // Submit form after validation
                });
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PersonalInfo;
const FormBoxStyle = {
  mt: "100px",
  width: { xs: "100%", md: "760px", lg: "100%" },
  borderRadius: "8px",
  border: "1px solid #DFE4EA",
  boxShadow: " 0px 0px 4px 0px #E9E9E924",
  padding: { xs: "16px", sm: "20px", md: "24px" },
};
const PhoneBoxStyle = {
  height: { xs: "80px", sm: "92px" },
  mb: { xs: "12px", sm: "0" },
  width: "100%",
};
const PrimaryBtnStye = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  mt: "12px",
};
