import { LogoutIcons } from "@/assets/images";
import { useLogoutFunction } from "@/hooks/Logout";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const MenuItem = ({ to, text, toggleDrawer }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isSelected = location?.pathname === to;

  const handleClick = () => {
    navigate(to);
    toggleDrawer();
  };

  return (
    <ListItem disablePadding>
      <ListItemButton selected={isSelected} onClick={handleClick}>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  );
};

const DashboardMobileView = ({ toggleDrawer }) => {
  const logoutHook = useLogoutFunction();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "calc(100% - 52px)",
      }}
    >
      <List>
        <MenuItem
          to="/my-projects"
          text="My Projects"
          toggleDrawer={toggleDrawer}
        />
        {/* <MenuItem
          to="/shared-projects"
          text="Shared Projects"
          toggleDrawer={toggleDrawer}
        /> */}
        <MenuItem
          to="/create-website"
          text="Create a website"
          toggleDrawer={toggleDrawer}
        />
        <MenuItem
          to="/profile"
          text="My Profile"
          toggleDrawer={toggleDrawer}
        />
      </List>
      <Box>
        <Box
          onClick={(e) => {
            e.preventDefault();
            logoutHook();
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            px: "6px",
            ":hover": {
              backgroundColor: "#E0DAF9",
            },
          }}
        >
          <img src={LogoutIcons} alt="logout" style={ImageStyle} />
          <Typography variant="body1">Logout</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardMobileView;

const ImageStyle = {
  cursor: "pointer",
  width: "36px",
  height: "36px",
  marginRight: "6px",
};
