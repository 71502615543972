import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import PasswordIcon from "@mui/icons-material/Password";
import { Box, Typography } from "@mui/material";

export default function CustomModel(props) {
  const { title, buttonTitle, data } = props;
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        startIcon={<PasswordIcon sx={{ height: "16px", width: "20px" }} />}
        sx={PreviewButtonStyle}
        onClick={handleClickOpen}
      >
        {buttonTitle || "Button"}
      </Button>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {title || "Model"}
        </DialogTitle>
        <DialogContent sx={{ minWidth: "320px" }}>
          <DialogContentText>
            {data ? (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1">
                  <strong>Teacher Credentials:</strong>
                </Typography>
                <Typography variant="body2">
                  Email:{" "}
                  <Box
                    component="span"
                    sx={{
                      fontFamily: "monospace",
                      bgcolor: "#f0f0f0",
                      px: 1,
                      py: 0.5,
                      borderRadius: 1,
                    }}
                  >
                    teacher@example.com
                  </Box>
                </Typography>
                <Typography variant="body2">
                  Password:{" "}
                  <Box
                    component="span"
                    sx={{
                      fontFamily: "monospace",
                      bgcolor: "#f0f0f0",
                      px: 1,
                      py: 0.5,
                      borderRadius: 1,
                    }}
                  >
                    Teacher@123
                  </Box>
                </Typography>
              </Box>
            ) : (
              "No Credentials app"
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const PreviewButtonStyle = {
  borderColor: "#fff",
  display: { xs: "none", md: "flex" },
  mx: "8px",
  border: "2px solid #7C4CE1",
  color: "#7C4CE1",
};
