import {
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LogoImage } from "@/assets/images";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { useLogoutFunction } from "@/hooks/Logout";
import { useAppSelector } from "@/redux/store";
import { useTranslation } from "react-i18next";

interface SideBarProps {
  handleClose: () => void;
  open: boolean;
}

const index: React.FC<SideBarProps> = (props) => {
  const logout = useLogoutFunction();
  const navigate = useNavigate();
  const { open, handleClose } = props;
  const token = useAppSelector((state) => state.auth.auth.accessToken);
  const { t } = useTranslation();
  return (
    <Drawer open={open} anchor={"right"} onClose={handleClose}>
      <Box
        sx={{
          width: 270,
          height: "100%",
        }}
        role="presentation"
      >
        <Box sx={{ height: "64px", p: "18px 12px" }}>
          <Box sx={boxStyle}>
            <Box sx={{ height: "20px" }}>
              <img
                src={LogoImage}
                alt="logo"
                style={{ height: "100%", width: "100%" }}
              />
            </Box>
            <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
          </Box>
        </Box>
        <Box sx={InnerBoxStyle}>
          {" "}
          <Box sx={{ width: "100%" }}>
            <List component="nav" aria-label="main mailbox folders">
              <ListItemButton
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/", {
                    state: { scrollTo: "icode-features-section" },
                  });
                  handleClose();
                }}
              >
                <ListItemText primary={t("LandingPage.Feature")} />
              </ListItemButton>
              <ListItemButton
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/", {
                    state: { scrollTo: "icode-contact-us-section" },
                  });
                  handleClose();
                }}
              >
                <ListItemText primary={t("LandingPage.Contact_US")} />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate("/pricing");
                  handleClose();
                }}
              >
                <ListItemText primary="Pricing" />
              </ListItemButton>
            </List>
          </Box>
          <Box sx={{}}>
            <Divider />
            <Box sx={{ p: "14px" }}>
              <Button
                variant="text"
                sx={{ mr: "12px" }}
                onClick={() => {
                  handleClose();
                  setTimeout(() => {
                    if (token) {
                      logout();
                    } else {
                      navigate("/login");
                    }
                  }, 5);
                }}
              >
                {token ? "Logout" : "Login"}
              </Button>{" "}
              <PrimaryButton
                text={token ? "Dashboard" : "Sign Up for Free"}
                onClick={() => {
                  handleClose();
                  setTimeout(() => {
                    navigate(token ? "/my-projects" : "/signup");
                  }, 5);
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default index;

// styling

const boxStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const InnerBoxStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "column",
  height: "calc(100% - 64px)",
  width: "100%",
};
