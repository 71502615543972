import { Button } from "@mui/material";

export const ImageMenuComp = (props) => {
  const { imgSrc, title, style } = props;
  return (
    <>
      <img
        src={imgSrc}
        alt={title}
        style={{
          ...style,
          minHeight: "20px",
          height: "20px",
          minWidth: "20px",
          width: "20px",
          marginRight: "12px",
        }}
      />
    </>
  );
};

export const IconButtonComp = (props) => {
  const { onClick, imgSrc, altText, height, width, minWidth,svgSrc,borderRadius,style } = props;
  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={onClick}
        sx={{
          ...style,
          height: height ? height : "40px",
          minWidth: minWidth ? minWidth : "40px",
          width: width ? width : "40px",
          bgcolor: "transport",
          borderRadius:borderRadius?borderRadius: "6px",
          textTransform: "none",
          color: "text.primary",
          "&:hover": {
            bgcolor: "#E5E7EB",
          },
        }}
      >
        {svgSrc
        ?
        svgSrc:
  <img
  src={imgSrc}
  alt={altText}
  style={{ height: "100%", width: "100%" }}
/>
        }
      
      </Button>
    </>
  );
};
