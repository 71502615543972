import * as React from "react";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DashboardMenuIcon,
  GuestProfileImage,
  logoutIcons,
  ProfileMenuIcon,
} from "@/assets/images";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setClearState } from "@/redux/slices/userSlice";
import { useLogoutFunction } from "@/hooks/Logout";
import { ImageMenuComp } from "@/components/image";

const ProfileMenu = () => {
  const ProfileData = useAppSelector((state) => state.user.user);
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const logoutHook = useLogoutFunction();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 ,borderRadius:"8px"}}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            {ProfileData?.type === "guest" ? (
              <Avatar sx={{ width: 48, height: 48 }}>
                <img
                  src={GuestProfileImage}
                  alt="Guest Profile"
                  width="100%"
                  height="100%"
                />
              </Avatar>
            ) : ProfileData?.profileImage ? (
              <Avatar
                sx={{
                  width: 48,
                  height: 48,
                }}
              >
                <img
                  src={ProfileData?.profileImage}
                  alt="profile"
                  width="100%"
                  height="100%"
                />
              </Avatar>
            ) : (
              <Avatar sx={{ ...AvatarStyle(location), width: 48, height: 48 }}>
                {" "}
                {ProfileData?.fullName[0]?.toUpperCase() || "F"}
              </Avatar>
            )}
            <ExpandMoreRoundedIcon
              sx={{
                color: location.pathname.startsWith("/create-website")
                  ? "#fff"
                  : "",
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              maxWidth: "456px", // Set the width of the menu to 456px
              "& .MuiAvatar-root": {
                width: 48,
                height: 48,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box sx={{ display: "flex", p: 2, alignItems: "center" }}>
          <Box sx={{ mr: .5}}>
            {ProfileData?.profileImage ? (
              <Avatar
                sx={{
                  width: 48,
                  height: 48,
                }}
              >
                <img
                  src={ProfileData?.profileImage}
                  alt="profile"
                  width="100%"
                  height="100%"
                />
              </Avatar>
            ) : (
              <Avatar sx={{ ...AvatarStyle(location), width: 48, height: 48 }}>
                {ProfileData?.fullName[0]?.toUpperCase() || "F"}
              </Avatar>
            )}
          </Box>
          <Box sx={{ maxWidth: "calc(456px - 80px)", overflow: "hidden" }}>
            <Tooltip title={ProfileData?.fullName || "faiq"} placement="top">
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  fontWeight: 500,
                  maxWidth: "180px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  color:"#1E293B"

                }}
              >
                {ProfileData?.fullName || "Unknown"}
              </Typography>
            </Tooltip>
            <Tooltip title={ProfileData?.email || "faiq@faiq.com"} placement="bottom">
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  color: "#667085",
                  maxWidth: "180px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap"
                }}
              >
                {ProfileData?.email || "faiq@faiq.com"}
              </Typography>
            </Tooltip>
          </Box>
        </Box>
       
        <MenuItem
          onClick={() => {
            handleClose();
            setTimeout(() => {
              navigate("/my-projects");
            }, 5);
          }}
          sx={{ fontSize: "14px", fontWeight: "400", color: "#667085" }}
        >
          <ImageMenuComp imgSrc={DashboardMenuIcon} title="profile" />
          {t("LandingPage.Dashboard")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setTimeout(() => {
              navigate("/profile");
            }, 5);
          }}
          sx={{ fontSize: "14px", fontWeight: "400", color: "#667085" }}
        >
          <ImageMenuComp imgSrc={ProfileMenuIcon} title="profile" />
          {t("Dashboard.Profile")}
        </MenuItem>
        <Divider />

        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            handleClose();
            logoutHook();
            navigate("/");
            dispatch(setClearState());
          }}
          sx={{ fontSize: "14px", fontWeight: "400", color: "#667085" }}
        >
          <ImageMenuComp imgSrc={logoutIcons} title="logout" />

          {t("LandingPage.Logout")}
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default ProfileMenu;

const AvatarStyle = (location) => ({
  width: location.pathname === "/create-website" ? 36 : 40,
  height: location.pathname === "/create-website" ? 36 : 40,
  background: location.pathname === "/create-website" ? "#fff" : "#7C4CE1",
  color: location.pathname === "/create-website" ? "#7C4CE1" : "#fff",
});
