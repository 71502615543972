import { Box, FormHelperText, TextField, Typography } from "@mui/material";

const wrapper = {
  height: "20px",
  width: "100%",
  mt: "2px",
};

const errorStyle = { paddingLeft: "0", mt: 0 };

interface PrimaryTextFieldProps {
  title?: string;
  placeholder?: string;
  value: string;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  touched?: boolean;
  errors?: string | undefined;
  name: string;
  style?: any;
  disabled?: boolean;
}

const PrimaryTextField: React.FC<PrimaryTextFieldProps> = (props) => {
  const {
    title,
    placeholder,
    value,
    handleChange,
    handleBlur,
    touched,
    errors,
    name,
    disabled,
  } = props;
  return (
    <Box
      sx={{
        height: { xs: "80px", sm: "96px" },
        mb: { xs: "12px", sm: "0" },
        width: "100%",
      }}
    >
      {title && (
        <Typography variant="h6" sx={{ pb: "6px", fontWeight: "500" }}>
          {title || "Password"}
        </Typography>
      )}
      <TextField
        name={name}
        disabled={disabled}
        placeholder={placeholder || "Enter here"}
        onChange={handleChange}
        // sx={textField}
        onBlur={handleBlur}
        fullWidth
        value={value}
        sx={{
          minHeight: "46px",
          height: "46px",
          "& .MuiOutlinedInput-root": {
            color: "#667085",
            fontWeight: "400",
            height: "46px",
          },
        }}
        inputProps={{
          "data-cy": "linked_in-personaldetail", // Cypress ID assigned to the search input element
        }}
        error={touched && Boolean(errors)}
      />
      <Box sx={wrapper}>
        {touched && Boolean(errors) && (
          <FormHelperText error sx={errorStyle}>
            {touched && errors}
          </FormHelperText>
        )}
      </Box>
    </Box>
  );
};

export default PrimaryTextField;
