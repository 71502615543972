// ----------------------------------------------------------------------

import { PaletteMode } from "@mui/material";
import palette from "./palette";

export function remToPx(value: string): number {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value: number): string {
  return `${value / 16}rem`;
}

interface ResponsiveFontSizesOptions {
  xs: number;
  sm: number;
  md: number;
  lg: number;
}

export function responsiveFontSizes(options: ResponsiveFontSizesOptions): {
  [key: string]: { fontSize: string };
} {
  const { xs, sm, md, lg } = options;
  return {
    "@media (max-width:599px)": {
      fontSize: pxToRem(xs),
    },
    "@media (min-width:600px)": {
      fontSize: pxToRem(sm),
    },
    "@media (min-width:900px)": {
      fontSize: pxToRem(md),
    },
    "@media (min-width:1200px)": {
      fontSize: pxToRem(lg),
    },
  };
}

// ----------------------------------------------------------------------

// Google Font
// const FONT_PRIMARY = 'CircularStd, sans-serif'; // Local Font

const typography = (mode: PaletteMode, direction: string) => {
  const FONT_PRIMARY = direction === "rtl" ? "Tajawal" : "Inter";
  const FONT_BOLD = direction === "rtl" ? "Tajawal" : "Inter";
  return {
    // fontFamily: FONT_PRIMARY,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,

    h1: {
      fontFamily: FONT_BOLD,
      fontWeight: 800,
      lineHeight: 28 / 18,
      fontSize: pxToRem(60),
      textAlign: "center",
      color: palette(mode).text.primary,
      ...responsiveFontSizes({ xs: 48, sm: 56, md: 60, lg: 60 }),
    },
    h2: {
      fontFamily: FONT_BOLD,
      fontWeight: 700,
      lineHeight: 1.2,
      fontSize: pxToRem(48),
      color: palette(mode).text.primary,
      ...responsiveFontSizes({ xs: 38, sm: 42, md: 48, lg: 48 }),
    },
    h3: {
      fontFamily: FONT_BOLD,
      fontWeight: 600,
      lineHeight:"30px",
      fontSize: pxToRem(27),
      color: palette(mode).text.primary,
      ...responsiveFontSizes({ xs: 20, sm: 22, md: 24, lg: 24 }),
    },
    h4: {
      fontFamily: FONT_BOLD,
      fontWeight: 500,
      lineHeight: 1.5,
      fontSize: pxToRem(24),
      color: palette(mode).text.primary,
      ...responsiveFontSizes({ xs: 20, sm: 20, md: 22, lg: 24 }),
    },
    h5: {
      fontFamily: FONT_BOLD,
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(16),
      color: palette(mode).text.primary,
      ...responsiveFontSizes({ xs: 16, sm: 16, md: 16, lg: 16 }),
    },
    h6: {
      fontFamily: FONT_BOLD,
      fontWeight: 600,
      lineHeight: 28 / 18,
      fontSize: pxToRem(16),
      color: palette(mode).text.primary,
      ...responsiveFontSizes({ xs: 16, sm: 16, md: 16, lg: 16 }),
    },
    subtitle1: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 400,
      lineHeight: 1.2,
      fontSize: pxToRem(14),
      textAlign: "left",
      color: palette(mode).text.secondary,
      ...responsiveFontSizes({ xs: 14, sm: 14, md: 14, lg: 14 }),
    },
    subtitle2: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 500,
      lineHeight: 1.5,
      fontSize: pxToRem(14),
      textAlign: "center",
      color: palette(mode).text.secondary,
      cursor: "pointer",
      ...responsiveFontSizes({ xs: 13, sm: 14, md: 14, lg: 14 }),
    },
    body1: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 500,
      lineHeight: 22 / 14,
      fontSize: pxToRem(16),
      color: palette(mode).text.secondary,
      ...responsiveFontSizes({ xs: 13, sm: 14, md: 16, lg: 16 }),
    },
    body2: {
      fontFamily: FONT_BOLD,
      fontWeight: 600,
      lineHeight: 28 / 18,
      fontSize: pxToRem(18),
      color: palette(mode).text.primary,
      ...responsiveFontSizes({ xs: 14, sm: 14, md: 16, lg: 18 }),
    },

    caption: {
      fontFamily: FONT_PRIMARY,
      lineHeight: 1.5,
      fontSize: pxToRem(12),
    },

    overline: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(12),
      textTransform: "uppercase",
    },
    button: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 500,
      lineHeight: 24 / 14,
      fontSize: pxToRem(14),
      textTransform: "capitalize",
    },
  };
};

export default typography;
