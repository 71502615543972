import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "../redux/store";
import Privatelayout from "@/layout/privateLayout";

const VerifiedRoutes = () => {
  const { accessToken,userVerified } = useAppSelector((state) => state.auth.auth);
  const location = useLocation();
  const isAuthenticated = accessToken;

  // If not authenticated, redirect to login
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // If authenticated but not verified, redirect to verify-email
  if (!userVerified) {
    return <Navigate to="/verify-email" />;
  }

  // If authenticated and verified, render private layout with outlet
  return (
    <Privatelayout location={location}>
      <Outlet />
    </Privatelayout>
  );
};

export default VerifiedRoutes;
