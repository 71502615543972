import { ProjectNameIcon, SaveProjectNameIcons } from "@/assets/images";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { updateProjectName } from "@/redux/thunk/projectThunk";
import { Box, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const UpdateProjectName = () => {
  const { id } = useParams();
  const { projectRole, projectName } = useAppSelector((state) => state.prompt);
  const [openName, setOpenName] = React.useState(false);
  const { t } = useTranslation();
  const [projectNameValue, setProjectNameValue] = React.useState(
    projectName || ""
  );
  const dispatch = useAppDispatch();

  const UpdateProjectName = async (projectNameValue: string) => {
    const data: {
      projectName: string;
      projectId: string;
    } = {
      projectName: projectNameValue.trim(),
      projectId: id,
    };

    const resultAction = await dispatch(updateProjectName(data));
    if (updateProjectName.fulfilled.match(resultAction)) {
      toast.success(
        resultAction?.payload?.message || "Project Name Successfully"
      );
    } else if (updateProjectName.rejected.match(resultAction)) {
      toast.error(resultAction?.payload?.reason || "Something went wronge");
    }
  };

  useEffect(() => {
    if (projectName) {
      setProjectNameValue(projectName);
    }
  }, [projectName]);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        mx: "8px",
        maxWidth: { xs: "144px", sm: "160px", md: "240px", lg: "300px" },
      }}
    >
      {openName ? (
        <TextField
          id="outlined-basic"
          placeholder={t("Header.Enter_project_name")}
          variant="outlined"
          size="small"
          fullWidth
          value={projectNameValue}
          onChange={(e) => setProjectNameValue(e.target.value)}
          onBlur={() => {
            if (projectNameValue.trim() !== projectName) {
              UpdateProjectName(projectNameValue);
            }
            setOpenName(false);
          }}
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              bgcolor: "#EAEDF1",
              height: "40px",
              width: "100%",
            },
          }}
        />
      ) : (
        <Tooltip
          title={
            projectNameValue ? projectNameValue : projectName || "Project Name"
          }
        >
          <Typography variant="body1" sx={TypoStyle}>
            {projectNameValue
              ? projectNameValue
              : projectName || "Project Name"}
          </Typography>
        </Tooltip>
      )}
      {["owner", "admin", "editor"].includes(projectRole) && (
        <img
          src={openName ? SaveProjectNameIcons : ProjectNameIcon}
          alt="project name "
          style={ImageStyle}
          onClick={() => {
            setOpenName(true);
            openName && UpdateProjectName(projectNameValue);
            openName && setOpenName(false);
          }}
        />
      )}
    </Box>
  );
};

export default UpdateProjectName;

const ImageStyle = {
  height: "20px",
  width: "20px",
  marginLeft: "8px",
  cursor: "pointer",
};

const TypoStyle = {
  maxWidth: { xs: "144px", sm: "160px", md: "240px", lg: "300px" },
  whiteSpace: "nowrap",
  overflow: "hidden !important",
  textOverflow: "ellipsis",
  textTransform: "capitalize",
};
