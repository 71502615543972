import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Button, Typography } from "@mui/material";
import {
  colorMenuEditorIcon,
  emailMenuEditorIcon,
  FontMenuEditorColor,
} from "@/assets/images";
import { useAppDispatch } from "@/redux/store";
import {
  setAIEditorToggle,
  setCommentsModel,
  setEmailCredintials,
  setOpenGlobalFontFamily,
  setOpenGlobalPallets,
  setOpenGlobalTheme,
} from "@/redux/slices/toolsSlice";
import { setProductionModel } from "@/redux/slices/userSlice";
import { ImageMenuComp } from "../image";

// Define the props type
interface EditorMenuButtonProps {
  imgSrc?: string;
  title?: string;
}

interface StyleItem {
  imgSrc: string;
  title: string;
  eventsFuns?: () => void;
}

const EditorMenuButton: React.FC<EditorMenuButtonProps> = (props) => {
  const { imgSrc, title } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Define the data outside the component or use useMemo if it depends on props
  const mapDataStyle: StyleItem[] = [
    {
      imgSrc: colorMenuEditorIcon,
      title: "Color",
      eventsFuns: () => {
        dispatch(setAIEditorToggle(false));
        dispatch(setOpenGlobalFontFamily(false));
        dispatch(setProductionModel(false));
        dispatch(setOpenGlobalPallets(true));
        dispatch(setOpenGlobalTheme(false));
        dispatch(setEmailCredintials(false));
        dispatch(setCommentsModel(false));
      },
    },
    {
      imgSrc: FontMenuEditorColor,
      title: "Font",
      eventsFuns: () => {
        dispatch(setAIEditorToggle(false));
        dispatch(setOpenGlobalFontFamily(true));
        dispatch(setProductionModel(false));
        dispatch(setOpenGlobalPallets(false));
        dispatch(setOpenGlobalTheme(false));
        dispatch(setEmailCredintials(false));
        dispatch(setCommentsModel(false));
      },
    },
    // {
    //   imgSrc: templateMenuEditorIcon,
    //   title: "Template",
    //   eventsFuns: () => {
    //     dispatch(setAIEditorToggle(false));
    //     dispatch(setOpenGlobalFontFamily(false));
    //     dispatch(setProductionModel(false));
    //     dispatch(setOpenGlobalPallets(false));
    //     dispatch(setOpenGlobalTheme(true));
    //     dispatch(setEmailCredintials(false));
    //     dispatch(setCommentsModel(false));
    //   },
    // },
    {
      imgSrc: emailMenuEditorIcon,
      title: "Email",
      eventsFuns: () => {
        dispatch(setAIEditorToggle(false));
        dispatch(setEmailCredintials(true));
        dispatch(setOpenGlobalFontFamily(false));
        dispatch(setProductionModel(false));
        dispatch(setOpenGlobalPallets(false));
        dispatch(setOpenGlobalTheme(false));
        dispatch(setCommentsModel(false));
      },
    },
  ];

  const handleMenuItemClick = (item: StyleItem) => {
    if (item.eventsFuns) {
      item.eventsFuns();
    }
    handleClose();
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          height: "32px",
          minWidth: "78px",
          bgcolor: "#F3F4F6",
          borderRadius: "6px",
          textTransform: "none",
          color: "text.primary",
          "&:hover": {
            bgcolor: "#E5E7EB",
          },
        }}
      >
        {imgSrc && <img src={imgSrc} alt="style" />}
        {title && (
          <span style={{ color: "#374151", marginLeft: "6px" }}>{title}</span>
        )}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                left: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
      >
        {mapDataStyle.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => handleMenuItemClick(item)}
            sx={{ minWidth: "160px" }}
          >
            <ImageMenuComp imgSrc={item.imgSrc} title={`image-${index}`} />
            <Typography variant="subtitle2" sx={{ fontWeight: "400" }}>
              {item.title}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default EditorMenuButton;
